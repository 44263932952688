import { ComponentPropsWithoutRef, ComponentPropsWithRef, ReactNode, forwardRef } from "react";
import { useId } from "react-aria";
import styled from "styled-components";

import Checkbox from "../../uikit/Checkbox";
import Text from "../../uikit/Text";

type Props = ComponentPropsWithoutRef<typeof Checkbox> & {
    children: ReactNode;
    className?: string;
};

const CheckboxFilter = (
    { children, className, ...rest }: Props,
    ref: ComponentPropsWithRef<typeof Checkbox>["ref"],
) => {
    const checkboxUuid = useId();
    return (
        <Text as="label" className={className} data-cy="checkbox-filter" lines={2} htmlFor={checkboxUuid}>
            <Checkbox {...rest} ref={ref} id={checkboxUuid} />
            <Text as="span" lines={2}>
                {children}
            </Text>
        </Text>
    );
};

const CHECKBOX_MARGIN = "3px";

export default styled(forwardRef(CheckboxFilter))`
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    height: 48px;
    padding: 0 ${({ theme }) => theme.spacing[4]};
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};

    display: inline-flex;
    background-color: ${({ theme }) => theme.palette.white.base};
    align-items: center;
    box-sizing: border-box;

    cursor: pointer;

    ${Checkbox} {
        margin-right: calc(${({ theme }) => theme.spacing[4]} - ${CHECKBOX_MARGIN});
        margin-left: -${CHECKBOX_MARGIN};
    }

    > span:last-child {
        flex: 1;
    }
`;
