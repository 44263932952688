import React from "react";
import { useTranslation } from "react-i18next";

import BriefsView from "../BriefListView";
import { BriefsViewFilter, useFilters } from "../BriefListView/filters";

const BriefsExplore = () => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListPage.BriefsExplore" });

    const filters = useFilters(
        [
            { viewFilter: BriefsViewFilter.FRAMEWORKS },
            { viewFilter: BriefsViewFilter.SKILLS },
            { viewFilter: BriefsViewFilter.TAGS },
            { viewFilter: BriefsViewFilter.LANGUAGES },
        ],
        [],
    );

    return (
        <BriefsView
            title={t("title")}
            welcomeMessage={t("welcomeMessage")}
            filters={filters}
            queryFilters={queryFilters => queryFilters}
        />
    );
};

export default BriefsExplore;
