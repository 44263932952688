import React, { FC, RefObject, useRef } from "react";
import { AriaButtonProps, useButton, useFocusRing } from "react-aria";

import { Loader } from "@sol/uikit/core/icons";

import { ghostButtonVariants, ghostButtonLoaderVariants, type GhostButtonVariantProps } from "./variants";

export interface GhostButtonProps extends AriaButtonProps, GhostButtonVariantProps {
    isLoading?: boolean;
    disabled?: boolean;
    forwardRef?: RefObject<HTMLButtonElement>;
    className?: string;
}

export const GhostButton: FC<GhostButtonProps> = ({ variant, isLoading, disabled, forwardRef, ...props }) => {
    const { children, className = "" } = props;

    const ref = forwardRef ?? useRef(null);
    const { buttonProps } = useButton(props, ref);
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
        <button
            {...buttonProps}
            {...focusProps}
            className={ghostButtonVariants({
                variant,
                className: `${className} ${isFocusVisible ? "focus-classes" : ""}`,
            })}
            disabled={disabled || isLoading}
        >
            {isLoading ? (
                <Loader
                    size={32}
                    fill={`${variant ? ghostButtonLoaderVariants[variant].fillColor : "white-base"}`}
                    stroke={`${variant ? ghostButtonLoaderVariants[variant].strokeColor : "white-base"}`}
                />
            ) : (
                children
            )}
        </button>
    );
};
