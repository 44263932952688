import { cva, VariantProps } from "class-variance-authority";

export type GhostButtonVariantProps = VariantProps<typeof ghostButtonVariants>;

const baseClasses = [
    "flex",
    "justify-center",
    "items-center",
    "gap-3",
    "w-fit",
    "py-3",
    "px-6",
    "font-main",
    "font-bold",
    "text-base",
    "transition-all",
    "ease-in-out",
    "rounded-medium",
    "focus:outline-none",
    "focus-visible:outline-none",
    "focus-visible:ring",
    "focus-visible:ring-blue-focus",
    "focus-visible:ring-blue-focus",
    "focus-visible:ring-offset-2",
    "focus-visible:ring-offset-white-base",
    "disabled:opacity-60",
    "disabled:cursor-not-allowed",
];
export const ghostButtonVariants = cva(baseClasses, {
    variants: {
        variant: {
            primary: ["text-purple-base", "enabled:hover:bg-purple-lighter", "active:bg-purple-lighter"],
            secondary: ["text-grey-base", "enabled:hover:bg-grey-lighter", "active:bg-grey-lighter"],
            success: ["text-green-base", "enabled:hover:bg-green-lightest", "active:bg-grey-lighter"],
            danger: ["text-red-base", "enabled:hover:bg-red-lightest", "active:bg-red-lighter"],
        },
    },
    defaultVariants: { variant: "primary" },
});

export const ghostButtonLoaderVariants = {
    primary: { fillColor: "purple-base", strokeColor: "purple-base" },
    secondary: { fillColor: "grey-base", strokeColor: "grey-base" },
    success: { fillColor: "green-base", strokeColor: "green-base" },
    danger: { fillColor: "red-base", strokeColor: "red-base" },
};
