import React, { ChangeEvent } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import Checkbox, { CheckboxColor } from "src/uikit/Checkbox";
import styled from "styled-components";

import { ISkillItem } from "@sol/sdk";

import { Radio, RadioGroup } from "../../../../uikit/Radio";

const LevelsRadioGroup = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.spacing[3]};
    height: 24px;

    ${Radio} {
        flex: 1;
    }

    ${Radio} + ${Radio} {
        margin-left: ${({ theme }) => theme.spacing[3]};
    }
`;

const CheckboxContainer = styled.label`
    display: flex;
    align-items: center;

    width: 100%;
    padding: ${({ theme }) => theme.spacing[3]};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    background-color: ${({ theme }) => theme.palette.white.base};

    cursor: pointer;

    ${Text} {
        margin-left: ${({ theme }) => theme.spacing[4]};
    }
`;

type Props = {
    className?: string;
    skill: ISkillItem;
    onChange: (value: string | null) => void;
    value: string | null;
    levels?: boolean;
};

const SkillsPickerSkill = (props: Props) => {
    const { className, skill, onChange, value, levels } = props;
    const checkboxId = useId();

    const {
        ["@id"]: id,
        shortTitle,
        code,
        skillLevel1: { "@id": skillLevel1 },
        skillLevel2: { "@id": skillLevel2 },
        skillLevel3: { "@id": skillLevel3 },
    } = skill;

    const [t] = useTranslation();

    return (
        <div className={className} role="group" aria-labelledby={id} data-cy={id}>
            <CheckboxContainer data-cy="skill-label-filter" htmlFor={checkboxId}>
                <Checkbox
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChange(event.target.checked ? skill["@id"] : null)
                    }
                    checked={!!value}
                    color={CheckboxColor.GREEN}
                    id={checkboxId}
                />
                <Text id={code}>
                    <span className="font-bold">{code}</span>. <span>{shortTitle}</span>
                </Text>
            </CheckboxContainer>

            {levels && (
                <LevelsRadioGroup>
                    <RadioGroup
                        name={`${id}-level`}
                        onChange={onChange}
                        value={value && [skillLevel1, skillLevel2, skillLevel3].includes(value) ? value : null}
                    >
                        <Radio value={skillLevel1}>{t(`component.skillLevelChip.level1`)}</Radio>
                        <Radio value={skillLevel2}>{t(`component.skillLevelChip.level2`)}</Radio>
                        <Radio value={skillLevel3}>{t(`component.skillLevelChip.level3`)}</Radio>
                    </RadioGroup>
                </LevelsRadioGroup>
            )}
        </div>
    );
};

export default styled(SkillsPickerSkill)`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: 1px;

    background: ${({ theme }) => theme.palette.grey.lightest};
`;
