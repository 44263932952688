import React, { ReactElement, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import SROnly from "../SROnly";
import Pager from "./IPager";
import NavigationButton from "./NavigationButton";
import NavigationIcon from "./NavigationIcon";

export type PaginationProps = {
    /**
     * The current page
     */
    current: number;
    /**
     * The total number of pages
     */
    total: number;
    /**
     * A callback to trigger page change
     * @param page the new page
     */
    onChange: (page: number) => void;
    /**
     * A pager render props to display page navigations between arrows
     */
    pager?: (options: Pager) => null | string | ReactElement<any>;
} & Omit<HTMLAttributes<HTMLDivElement>, "onChange">;

const Pagination = ({ current, total, onChange, pager, ...rest }: PaginationProps) => {
    const [t] = useTranslation();

    const hasPrevious = current > 1;
    const hasNext = current < total;

    const goToPreviousPage = () => {
        if (hasPrevious) {
            return onChange(current - 1);
        }
    };

    const goToNextPage = () => {
        if (hasNext) {
            return onChange(current + 1);
        }
    };

    return (
        <nav role="navigation" {...rest}>
            <ul>
                <li>
                    <NavigationButton
                        onClick={goToPreviousPage}
                        disabled={!hasPrevious}
                        data-cy="pagination-previous-button"
                    >
                        <SROnly>{t("uikit.pagination.goToPreviousPage")}</SROnly>
                        <NavigationIcon isPrev />
                    </NavigationButton>
                </li>

                {pager && pager({ current, total, onChange })}
                <li>
                    <NavigationButton onClick={goToNextPage} disabled={!hasNext} data-cy="pagination-next-button">
                        <SROnly>{t("uikit.pagination.goToNextPage")}</SROnly>
                        <NavigationIcon />
                    </NavigationButton>
                </li>
            </ul>
        </nav>
    );
};

// DEBT use-better-selectors
export default styled(Pagination)`
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
        &:first-of-type {
            margin-right: ${({ theme }) => theme.spacing[3]};
        }

        &:last-of-type {
            margin-left: ${({ theme }) => theme.spacing[3]};
        }
    }

    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
    }
`;
