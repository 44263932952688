import { useReducer, Reducer, useCallback, DependencyList } from "react";

enum ActionType {
    REQUEST,
    RESPONSE,
    ERROR,
    RESET,
}

type State<Data> = {
    loading: boolean;
    data?: Data;
    error?: Error;
};

type IAction<Data> =
    | { type: ActionType.REQUEST | ActionType.RESET }
    | { type: ActionType.RESPONSE; payload: Data }
    | { type: ActionType.ERROR; payload: Error };

const defaultState = { loading: false };

function reducer<Data>(state: State<Data>, action: IAction<Data>): State<Data> {
    switch (action.type) {
        case ActionType.REQUEST:
            return {
                ...state,
                error: undefined,
                loading: true,
            };
        case ActionType.RESPONSE:
            return {
                data: action.payload,
                loading: false,
            };
        case ActionType.ERROR:
            return {
                error: action.payload,
                loading: false,
            };
        case ActionType.RESET:
            return defaultState;
        default:
            return state;
    }
}

type AnyAsyncFunction = (...args: any[]) => Promise<any>;
type ThenArg<T> = T extends Promise<infer U> ? U : T;
type AsyncReturnType<T extends AnyAsyncFunction> = ThenArg<ReturnType<T>>;

export const useAsyncCallback = <C extends AnyAsyncFunction, Data = AsyncReturnType<C>>(
    callback: C,
    deps: DependencyList,
): [C, State<Data>, () => void] => {
    const [state, dispatch] = useReducer(reducer as Reducer<State<Data>, IAction<Data>>, defaultState);

    const wrappedCallback = useCallback((...args: Parameters<C>) => {
        dispatch({ type: ActionType.REQUEST });
        const promise = callback(...args);

        return promise
            .then(value => {
                dispatch({ type: ActionType.RESPONSE, payload: value });
                return value;
            })
            .catch(error => {
                dispatch({ type: ActionType.ERROR, payload: error });
            });
    }, deps);

    const reset = useCallback(() => {
        dispatch({ type: ActionType.RESET });
    }, []);

    // TODO: find a better way to wrap async function without losing
    // typescript benefit
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return [wrappedCallback, state, reset];
};

export default useAsyncCallback;
