import { AxiosPromise } from "axios";
import { useCallback } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IUserList } from "../users";
import { serialize } from "../utils/url";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    email: string;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useCheckIsTrainer = ({ queryKey, email, ...options }: Params<IUserList, any>) => {
    const api = useApi();

    const url = `/users/trainer_by_email${serialize({ email }, true)}`;
    const queryFn = useCallback(() => unwrapAPIResult(api.get<IUserList>(url)), [api, url]);

    return useQuery<IUserList, any>({
        queryKey: queryKey || url,
        queryFn,
        config: { ...options, enabled: false, refetchOnMount: false },
    });
};
