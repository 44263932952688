import { once } from "lodash";
import { useMemo, useState, useLayoutEffect } from "react";
import { useIsFetching } from "react-query";

export const useAnchor = (id: string) => {
    const [initialized, setInitialized] = useState(false);

    const scroll = useMemo(
        () =>
            once(() => {
                const element = document.getElementById(id);
                element?.scrollIntoView({ behavior: "smooth" });
                setInitialized(true);
            }),
        [id],
    );

    const fetches = useIsFetching();

    useLayoutEffect(() => {
        if (fetches === 0 && !initialized) {
            const handler = setTimeout(() => scroll(), 250);

            return () => clearTimeout(handler);
        }
    }, [fetches, scroll]);

    return initialized;
};
