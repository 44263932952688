import styled from "styled-components";

import { ChevronDown } from "../icons";

const NavigationIcon = styled(ChevronDown)<{ isPrev?: boolean }>`
    transform: rotate(-${({ isPrev }) => (isPrev ? 270 : 90)}deg);
    height: 8px;
    margin: auto;

    .colorStroke {
        stroke: ${({ theme }) => theme.palette.purple.base};
    }
`;

export default NavigationIcon;
