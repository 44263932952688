import { cva } from "class-variance-authority";
import { MenuItemProps, MenuItem as ReactAriaMenuItem } from "react-aria-components";

import { focusRingVisibleInsetClasses } from "@sol/uikit/utils/focus";

const menuItemVariants = cva(
    [
        "cursor-pointer truncate rounded-lg px-3 py-2 text-sm outline-none transition-all duration-150 ease-in-out",
        ...focusRingVisibleInsetClasses,
    ],
    {
        variants: {
            variant: {
                primary: [
                    "bg-purple-base text-white-base",
                    "selected:bg-yellow-base selected:text-black-base",
                    "pressed:bg-yellow-base pressed:text-black-base",
                    "focus-visible:bg-yellow-base focus-visible:text-black-base",
                    "data-[hovered]:bg-yellow-base data-[hovered]:text-black-base",
                ],
                secondary: [
                    "bg-transparent-base text-black-base",
                    "selected:bg-purple-base selected:text-white-base",
                    "pressed:bg-purple-base pressed:text-white-base",
                    "focus-visible:bg-purple-base focus-visible:text-white-base",
                    "data-[hovered]:bg-purple-base data-[hovered]:text-white-base",
                ],
            },
        },
        defaultVariants: {
            variant: "primary",
        },
    },
);

type Props = {
    /**
     * Color/Style scheme to use
     * @default primary
     */
    variant?: "primary" | "secondary";
    className?: string;
} & MenuItemProps;

export const MenuItem = ({ variant = "primary", className, ...props }: Props) => {
    return <ReactAriaMenuItem {...props} className={menuItemVariants({ variant, className, ...props })} />;
};
