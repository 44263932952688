import styled, { css, DefaultTheme } from "styled-components";

// TODO useless theme typing usage
const style = ({ theme }: { theme: DefaultTheme }) => {
    return css`
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0;

        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 50%;

        cursor: pointer;
        box-sizing: border-box;
        transition: all 0.2s;
        width: 38px;
        height: 38px;

        &:focus {
            outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
            outline-offset: 2px;
        }

        &:not(:disabled):hover {
            background-color: ${theme.palette.yellow.base};
            border: 2px solid ${theme.palette.yellow.base};
        }

        &:active {
            border: 2px solid ${theme.palette.purple.base};
        }

        &:disabled {
            opacity: 0.5;
            cursor: default;
        }
    `;
};

const NavigationButton = styled.button`
    ${style}
`;

NavigationButton.displayName = "NavigationButton";

export default NavigationButton;
