import React from "react";
import styled from "styled-components";

import { CheckboxFilter } from "@sol/filters";
import { getResourceUuid, IClassroom, useFrameworks } from "@sol/sdk";
import { Grid, GridColumn } from "@sol/uikit";

type Props = {
    className?: string;
    value: string[];
    onChange: (value: string[]) => void;
    activeClassroom?: IClassroom;
};

const FrameworksPicker = ({ value, onChange, activeClassroom, ...props }: Props) => {
    const { data } = useFrameworks({
        filters: {
            classrooms: activeClassroom ? getResourceUuid(activeClassroom) : undefined,
        },
    });

    return (
        <Grid {...props}>
            {data?.["hydra:member"].map(({ "@id": id, title }) => {
                const checked = value.includes(id);

                return (
                    <GridColumn key={id} col={3}>
                        <CheckboxFilter
                            lines={2}
                            onChange={() => {
                                onChange(checked ? value.filter(vId => vId !== id) : [...value, id]);
                            }}
                            checked={checked}
                            value={id}
                        >
                            {title}
                        </CheckboxFilter>
                    </GridColumn>
                );
            })}
        </Grid>
    );
};

export default styled(FrameworksPicker)`
    ${CheckboxFilter} {
        height: 72px;
        width: 100%;
    }
`;
