import { motionValue } from "framer-motion";
import { TFunction } from "i18next";
import { ComponentPropsWithoutRef, ComponentType } from "react";
import { OnDateRangeChangeProps } from "react-date-range";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import {
    CheckboxFilterConfig,
    FilterType,
    ModalFilterConfig,
    PeriodFilterConfig,
    PickerFilterConfig,
} from "@sol/filters";
import { GhostButton } from "@sol/uikit/molecules/GhostButton";
import ObserveMotionValue from "@sol/utils/observeMotionValue";

import FrameworksPicker from "./pickers/FrameworksPicker";
import { IPickerAPI } from "./pickers/IPickerAPI";
import LanguagesPicker from "./pickers/LanguagesPicker";

const PickerWrapper = styled.div`
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    > * {
        min-height: 0;
        flex: 1;
    }

    > ${FrameworksPicker}, > ${LanguagesPicker} {
        overflow-y: auto;
    }
`;

export type FilterOptions = { backlist?: string[]; pinned?: boolean };

export const makeCheckboxFilter = (filter: string, choices: string[], options: { t: TFunction } & FilterOptions) => {
    const { t, backlist = [], pinned } = options;

    return {
        id: filter,
        label: t(`${filter}.label`),
        type: FilterType.CHECKBOX,
        choices: choices
            .filter(value => !backlist.includes(value))
            .map(value => {
                return {
                    value,
                    label: t(`${filter}.choices.${value}`),
                    pinned,
                };
            }),
    } as CheckboxFilterConfig;
};

export const makeModalFilter =
    (filter: string, t: TFunction) =>
    <C extends ComponentType<{ value: string[]; onChange: (value: string[]) => void }>>(
        PickerComponent: C,
        props?: ComponentPropsWithoutRef<C>,
    ) => {
        return {
            id: filter,
            label: t(`${filter}.label`),
            type: FilterType.MODAL,
            title: t(`${filter}.title`),
            count: (value: any) => value?.[filter]?.filter((x: any) => !!x).length ?? 0,
            // eslint-disable-next-line react/display-name
            render: () => {
                return (
                    <Controller
                        name={filter}
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <PickerWrapper>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    <PickerComponent
                                        value={value as string[]}
                                        onChange={(value: string[]) => {
                                            onChange(value);
                                        }}
                                        {...props}
                                    />
                                </PickerWrapper>
                            );
                        }}
                    />
                );
            },
        } as ModalFilterConfig;
    };

export const makePickerFilter =
    (filter: string, t: TFunction, options: { icon: JSX.Element; showResetButton?: boolean }) =>
    <
        C extends ComponentType<{
            value: string[];
            onChange: (value: string[]) => void;
        }>,
    >(
        PickerComponent: C,
        props?: ComponentPropsWithoutRef<C>,
    ) => {
        const pickerApiObservable = motionValue<IPickerAPI | null>(null);
        const handleApiChange = (api: IPickerAPI) => pickerApiObservable.set(api);
        return {
            id: filter,
            label: t(`${filter}.label`),
            title: t(`${filter}.title`),
            type: FilterType.PICKER,
            icon: options.icon,
            showResetButton: options.showResetButton,
            count: (value: any) => value?.[filter]?.filter((x: any) => !!x).length ?? 0,
            actions: () => {
                return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <ObserveMotionValue value={pickerApiObservable}>
                        {pickerApi => {
                            if (!pickerApi) {
                                return null;
                            }
                            const { isAllSelected, unSelectAll, selectAll } = pickerApi;
                            return (
                                <GhostButton
                                    variant="secondary"
                                    onPress={() => {
                                        if (isAllSelected) {
                                            unSelectAll();
                                        } else {
                                            selectAll();
                                        }
                                    }}
                                >
                                    {isAllSelected
                                        ? t(`${filter}.actions.unSelectAll`)
                                        : t(`${filter}.actions.selectAll`)}
                                </GhostButton>
                            );
                        }}
                    </ObserveMotionValue>
                );
            },
            render: () => {
                return (
                    <Controller
                        name={filter}
                        defaultValue={[]}
                        render={({ field: { value, onChange } }) => {
                            {
                                return (
                                    <>
                                        <PickerWrapper>
                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                            {/* @ts-ignore */}
                                            <PickerComponent
                                                value={value as string[]}
                                                onChange={(value: string[]) => {
                                                    onChange(value);
                                                }}
                                                onApiChange={handleApiChange}
                                                {...props}
                                            />
                                        </PickerWrapper>
                                    </>
                                );
                            }
                        }}
                    />
                );
            },
        } as PickerFilterConfig;
    };

export const makePeriodFilter =
    (filter: string, t: TFunction, options: { icon: JSX.Element }) =>
    <
        C extends ComponentType<{
            value: {
                key: string;
                startDate: Date;
                endDate: Date;
            };
            onChange: (range: OnDateRangeChangeProps) => void;
        }>,
    >(
        PickerComponent: C,
        props?: ComponentPropsWithoutRef<C>,
    ) => {
        return {
            id: filter,
            label: t(`${filter}.label`),
            type: FilterType.PERIOD,
            icon: options.icon,
            render: () => {
                return (
                    <Controller
                        name={filter}
                        defaultValue={{ key: "period", startDate: new Date(), endDate: new Date() }}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <PickerWrapper>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    <PickerComponent value={value} onChange={onChange} {...props} />
                                </PickerWrapper>
                            );
                        }}
                    />
                );
            },
        } as PeriodFilterConfig;
    };
