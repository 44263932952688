import React, { useState, useCallback, DetailedHTMLProps, HTMLAttributes, useRef } from "react";
import styled from "styled-components";
import { Merge } from "type-fest";

import { ICategoryResource, ITagResource } from "@sol/sdk";

import { Tab, TabPanel, Tabs } from "../../../../uikit/Tabs";
import TagButton from "./TagButton";

type Props = Merge<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    {
        value: ITagResource[];
        onChange: (value: ITagResource[]) => void;
        categories: ICategoryResource[];
    }
>;

const Count = styled.span`
    margin-left: ${({ theme }) => theme.spacing[2]};
`;

const TagsPicker = ({ value, onChange, categories, ...props }: Props) => {
    const tabPanelRef = useRef<HTMLDivElement | null>(null);

    const [selectedTabIndex, setselectedTabIndex] = useState(0);

    const selectedCategory = categories[selectedTabIndex];

    const tags = selectedCategory?.tags;

    const handleTabSelection = useCallback(
        (selectedTabIndex: number) => {
            setselectedTabIndex(selectedTabIndex);
            tabPanelRef.current?.focus();
        },
        [setselectedTabIndex],
    );

    return (
        <div {...props}>
            <Tabs>
                {categories?.map((category, index) => {
                    const selectedTagsCount = category.tags.filter(tag =>
                        value.some(val => val["@id"] === tag["@id"]),
                    ).length;
                    const isSelected = index === selectedTabIndex;

                    return category.tags.length > 0 ? (
                        <Tab
                            key={category["@id"]}
                            label={category.title}
                            isSelected={isSelected}
                            tabId={index}
                            onClick={() => handleTabSelection(index)}
                        >
                            {!!selectedTagsCount && <Count>({selectedTagsCount})</Count>}
                        </Tab>
                    ) : null;
                })}
            </Tabs>
            <TabPanel tabId={selectedTabIndex} tabIndex={-1} ref={tabPanelRef} className="focus-classes ring-inset">
                {tags?.map(tag => {
                    const tagId = tag["@id"];
                    const pressed = value.some(selectedTag => selectedTag["@id"] === tagId);

                    return (
                        <TagButton
                            key={tagId}
                            pressed={pressed}
                            onClick={() => {
                                onChange(
                                    pressed
                                        ? value.filter(value => value["@id"] !== tagId) // remove tag
                                        : [...value, tag], // add tag
                                );
                            }}
                        >
                            {tag.title}
                        </TagButton>
                    );
                })}
            </TabPanel>
        </div>
    );
};

export default styled(TagsPicker)`
    display: flex;
    flex-direction: column;

    ${TabPanel} {
        flex: 1;
        overflow: auto;

        padding: ${({ theme }) => theme.spacing[5]};

        display: flex;
        flex-wrap: wrap;
        gap: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[2]};
    }
`;
