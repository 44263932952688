import React from "react";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUser } from "@sol/authentication";

import BriefsView from "../BriefListView";
import { BriefsViewFilter, useFilters } from "../BriefListView/filters";

const MyBriefs = () => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListPage.MyBriefs" });
    const { user } = useAuthenticatedUser();

    const filters = useFilters([{ viewFilter: BriefsViewFilter.STATUS, pinned: true }], []);

    return (
        <BriefsView
            title={t("title")}
            welcomeMessage={t("welcomeMessage")}
            filters={filters}
            queryFilters={queryFilters => ({
                ...queryFilters,
                // It seems that we can't cumulate status=draft with createdByConnectedUser=true filter
                // should talk the TeamBack
                createdBy: user,
            })}
        />
    );
};

export default MyBriefs;
