import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FilterOptions, makeCheckboxFilter, makeModalFilter } from "src/components/shared/filters";
import TagIdsPicker from "src/components/shared/pickers/TagsPicker/TagIdsPicker";

import { AssignationType, BriefStatus, CorrectionStatus } from "@sol/sdk";

import FrameworksPicker from "../../shared/pickers/FrameworksPicker";
import LanguagesPicker from "../../shared/pickers/LanguagesPicker";
import SkillsPicker from "../../shared/pickers/SkillsPicker";

export enum BriefsViewFilter {
    STATUS = "status",
    CORRECTIONS_STATUS = "correctionStatus",
    ASSIGNATION_TYPE = "assignationType",
    CREATOR_TYPE = "creatorType",
    FRAMEWORKS = "frameworks",
    SKILLS = "skills",
    TAGS = "tags",
    LANGUAGES = "languages",
}

export const getFilter = (filter: BriefsViewFilter, options: { t: TFunction } & FilterOptions) => {
    const { t, backlist = [], pinned } = options;

    switch (filter) {
        case BriefsViewFilter.STATUS:
            return makeCheckboxFilter(filter, [BriefStatus.DRAFT, BriefStatus.VALIDATED], { t, backlist, pinned });
        case BriefsViewFilter.CREATOR_TYPE:
            return makeCheckboxFilter(filter, ["me", "others"], { t, backlist, pinned });
        case BriefsViewFilter.CORRECTIONS_STATUS:
            return makeCheckboxFilter(
                filter,
                [CorrectionStatus.TO_CORRECT, CorrectionStatus.CORRECTED, CorrectionStatus.WITHOUT_WORK],
                { t, backlist, pinned },
            );
        case BriefsViewFilter.ASSIGNATION_TYPE:
            return makeCheckboxFilter(
                filter,
                [AssignationType.GROUP, AssignationType.INDIVIDUAL, AssignationType.NONE],
                { t, backlist, pinned },
            );

        case BriefsViewFilter.FRAMEWORKS:
            return makeModalFilter(filter, t)(FrameworksPicker);
        case BriefsViewFilter.SKILLS:
            return makeModalFilter(filter, t)(SkillsPicker, { levels: true });

        case BriefsViewFilter.TAGS:
            return makeModalFilter(filter, t)(TagIdsPicker);
        case BriefsViewFilter.LANGUAGES:
            return makeModalFilter(filter, t)(LanguagesPicker);
    }
};

export const useFilters = (filters: ({ viewFilter: BriefsViewFilter } & FilterOptions)[], deps: any[]) => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListView.filters" });

    return useMemo(
        () =>
            filters.map(filter => {
                const { viewFilter, ...options } = filter;
                return getFilter(viewFilter, { t, ...options });
            }),
        [t, ...deps],
    );
};
