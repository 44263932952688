import React, { FC } from "react";
import styled, { css } from "styled-components";

import Text from "../../../uikit/Text";
import { PaletteColorType } from "../../../uikit/theme/palette";

type Variant = "primary" | "secondary";
type Type = "error" | "info" | "success";
type Position = "left" | "center" | "right";

const BG_COLOR: { [key: string]: { color: PaletteColorType; variant: { [key: string]: string } } } = {
    error: {
        color: "red",
        variant: {
            primary: "lightest",
            secondary: "base",
        },
    },
    info: {
        color: "purple",
        variant: {
            primary: "lightest",
            secondary: "base",
        },
    },
    success: {
        color: "green",
        variant: {
            primary: "lightest",
            secondary: "base",
        },
    },
};

const TEXT_COLOR: { [key: string]: { color: PaletteColorType; variant: { [key: string]: string } } } = {
    error: {
        color: "red",
        variant: {
            primary: "base",
            secondary: "lightest",
        },
    },
    info: {
        color: "purple",
        variant: {
            primary: "base",
            secondary: "lightest",
        },
    },
    success: {
        color: "green",
        variant: {
            primary: "base",
            secondary: "lightest",
        },
    },
};

const Anchor = styled.div`
    position: relative;
`;

const Triangle = styled.div<Pick<Props, "position">>`
    ${({ position }) => {
        return css`
            position: absolute;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 16px solid ${({ theme }) => theme.palette.red.lightest};
            bottom: -${({ theme }) => theme.spacing[5]};
            left: ${position === "left" ? "9px" : position === "center" ? 0 : null};
            right: ${position === "right" ? "9px" : position === "center" ? 0 : null};
            margin: ${position === "center" ? "auto" : null};
            border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
            border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        `;
    }}
`;

const MessageContainer = styled.div<Pick<Props, "variant" | "type" | "position">>`
    ${({ theme, type = "info", variant = "primary", position }) => {
        const bgColor = (theme.palette as any)[BG_COLOR[type].color][BG_COLOR[type].variant[variant]];
        const textColor = (theme.palette as any)[TEXT_COLOR[type].color][TEXT_COLOR[type].variant[variant]];
        return css`
            min-width: 200px;
            max-width: 600px;
            min-height: 40px;
            max-height: 160px;
            position: absolute;
            left: ${position === "left" ? 0 : position === "center" ? "-100%" : null};
            right: ${position === "right" ? 0 : position === "center" ? "-100%" : null};
            bottom: ${({ theme }) => theme.spacing[8]};
            padding: ${({ theme }) => theme.spacing[4]};
            background: ${bgColor};
            border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
            transition: opacity 0.2s ease-in-out;
            transform-origin: bottom center;
            z-index: ${({ theme }) => theme.zIndex.overlay};

            > ${Text} {
                color: ${textColor};
            }

            > ${Triangle} {
                border-top: 16px solid ${bgColor};
            }
        `;
    }}
`;

/**
 * Tooltip brings possibility to display MessageContainer on Hover if props onMouseEnter & onMouseLeave are passed
 * or provides the possibility to handle user click if needed, managed directly by children
 * @param text Bubble message text
 * @param type Type of message, ex: error, success, info as default
 * @param variant Variant color for the background and the text, ex: primary, secondary, primary as default
 * @param position Position of the bubble pointer and MessageContainer, ex left, right, center as default
 * @param displayTooltip Boolean wich set if Tooltip should be displayed or not
 * @param onMouseEnter Detect mouse enter
 * @param onMouseLeave Detect mouse leave
 */

type Props = {
    text: string;
    variant?: Variant;
    type?: Type;
    position?: Position;
    displayTooltip: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
};

const Tooltip: FC<Props> = ({
    children,
    text,
    type = "info",
    variant = "primary",
    position = "center",
    displayTooltip,
    onMouseEnter,
    onMouseLeave,
}) => {
    return (
        <Anchor data-cy="tooltip" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {displayTooltip && (
                <MessageContainer type={type} variant={variant} position={position}>
                    <Text variant="subheading" as="p">
                        {text}
                    </Text>
                    <Triangle position={position} />
                </MessageContainer>
            )}
            {children}
        </Anchor>
    );
};

export default Tooltip;
