import { yupResolver } from "@hookform/resolvers/yup";
import React, { ComponentPropsWithoutRef, useRef, ReactNode, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import { Label } from "src/uikit/TextInput";
import { InputStatus } from "src/uikit/TextInput/utils";
import styled from "styled-components";
import * as yup from "yup";

import { Loading } from "@sol/icons";
import { Typography } from "@sol/uikit/core/Typography";
import { Button } from "@sol/uikit/molecules/Button";

import TextField from "../TextField";

const Mandatory = styled(Text)`
    color: ${({ theme }) => theme.palette.red.base};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

type EmailLabelProps = {
    className?: string;
} & ComponentPropsWithoutRef<"label">;

const EmailLabel = ({ className, ...labelProps }: EmailLabelProps) => {
    const [t] = useTranslation();

    return (
        <Label {...labelProps} className={className}>
            {t("component.shared.UserPickerForm.inputs.email.label")}
            <span>*</span>
            <span> - {t("component.shared.UserPickerForm.inputs.email.helper")}</span>
        </Label>
    );
};

const StyledEmailLabel = styled(EmailLabel)`
    span :first-child {
        color: ${({ theme }) => theme.palette.red.base};
    }
    span :last-child {
        color: ${({ theme }) => theme.palette.grey.base};
    }
`;

export type AddUserFormData = yup.InferType<typeof schema>;

type Props = {
    title: string;
    subtitle?: string | ReactNode;
    onUserAdd: (formData: AddUserFormData) => void;
    showEmailOnly?: boolean;
    isLoading?: boolean;
    apiErrorMessage?: string;
    className?: string;
};

const schema = yup
    .object()
    .shape({
        email: yup.string().required().email(),
        name: yup.string(),
        surname: yup.string(),
    })
    .required();

const defaultValues = { email: "", name: "", surname: "" };

const UserPickerForm = ({
    title,
    subtitle,
    onUserAdd: onSubmit,
    isLoading = false,
    showEmailOnly,
    apiErrorMessage,
    className,
}: Props) => {
    const [t] = useTranslation();
    const firstErrorFieldRef = useRef<HTMLInputElement>(null);
    const emailFieldRef = useRef<HTMLInputElement>(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, errors },
    } = useForm<AddUserFormData>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        firstErrorFieldRef.current?.focus();
    }, [errors]);

    const onFormSubmit = (formData: AddUserFormData) => {
        onSubmit(formData);
        reset(defaultValues);
        emailFieldRef?.current?.focus();
    };

    return (
        <div className={className}>
            <div className="flex flex-col gap-3">
                <Typography tag="h2" variant="h4">
                    {title}
                </Typography>
                {typeof subtitle === "string" ? <Typography variant="label">{subtitle}</Typography> : subtitle}
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} data-cy="add-user-form" noValidate>
                <Mandatory variant="label">* {t("component.shared.UserPickerForm.mandatory")}</Mandatory>
                <Controller
                    control={control}
                    name="email"
                    render={({ field: { name, onChange, value }, fieldState: { invalid, error } }) => (
                        <TextField
                            data-cy="email"
                            ref={errors[name] ? firstErrorFieldRef : emailFieldRef}
                            name={name}
                            label={<StyledEmailLabel htmlFor={name} />}
                            id={name}
                            placeholder={t("component.shared.UserPickerForm.inputs.email.placeholder")}
                            required
                            onChange={onChange}
                            value={value}
                            feedback={error?.message || apiErrorMessage}
                            status={invalid || apiErrorMessage ? InputStatus.ERROR : InputStatus.DEFAULT}
                        />
                    )}
                />

                {!showEmailOnly &&
                    ["name", "surname"].map((fieldName: "name" | "surname") => (
                        <Controller
                            key={fieldName}
                            control={control}
                            name={fieldName}
                            render={({ field: { name, onChange, value }, fieldState: { invalid, error } }) => (
                                <TextField
                                    data-cy={fieldName}
                                    name={name}
                                    ref={errors[name] && firstErrorFieldRef}
                                    label={t(`component.shared.UserPickerForm.inputs.${fieldName}.label`)}
                                    id={name}
                                    placeholder={t(`component.shared.UserPickerForm.inputs.${fieldName}.placeholder`)}
                                    onChange={onChange}
                                    value={value}
                                    feedback={error?.message}
                                    status={invalid ? InputStatus.ERROR : InputStatus.DEFAULT}
                                />
                            )}
                        />
                    ))}

                <Button
                    data-cy="check-email-button"
                    type="submit"
                    variant="primary"
                    disabled={!isDirty || isLoading}
                    className="ml-auto mt-10"
                >
                    {t("component.shared.UserPickerForm.add")}
                    {isLoading && <Loading />}
                </Button>
            </form>
        </div>
    );
};

export default styled(UserPickerForm)`
    ${TextField} + ${TextField} {
        margin-top: ${({ theme }) => theme.spacing[7]};
    }

    form {
        margin-top: ${({ theme }) => theme.spacing[9]};
    }
`;
