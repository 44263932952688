import { FocusableRef } from "@react-types/shared";
import { cloneElement, forwardRef, ReactElement, RefObject } from "react";
import { AriaButtonProps, useButton, useId } from "react-aria";

import { Typography } from "@sol/uikit/core/Typography";
import { iconButtonVariants, iconClassNames, IconButtonVariantProps } from "@sol/uikit/molecules/IconButton/variants";

import { useFocusableRef } from "../../utils/useDOMRefs";

const IconButtonSkeleton = ({ position, variant }: { position?: "left" | "right" | null; variant: string }) => (
    <div className={`flex items-center ${position === "right" ? "flex-row-reverse" : ""} w-32 gap-3`}>
        <div className={`h-8 w-8 rounded-full ${variant === "secondary" ? "bg-grey-lightest" : "bg-white-base"}`}></div>
        <div
            className={`h-6 w-[84px] rounded-lg ${variant === "secondary" ? "bg-grey-lightest" : "bg-white-base"}`}
        ></div>
    </div>
);

export type IconButtonProps = {
    forwardRef?: RefObject<HTMLButtonElement>;
    isLoading?: boolean;
    disabled?: boolean;
    label: string;
    labelVisible?: boolean;
    children: ReactElement;
    variant: "primary" | "secondary";
    className?: string;
} & AriaButtonProps &
    IconButtonVariantProps;

const IconButton = (
    {
        variant,
        position,
        isLoading,
        disabled,
        label,
        labelVisible = false,
        children,
        className,
        ...props
    }: IconButtonProps,
    ref: FocusableRef<HTMLButtonElement>,
) => {
    const buttonRef = useFocusableRef<HTMLButtonElement>(ref);
    const elementId = useId();
    const { buttonProps } = useButton(props, buttonRef);

    return isLoading ? (
        <IconButtonSkeleton position={position} variant={variant} />
    ) : (
        <button
            {...buttonProps}
            className={iconButtonVariants({ position, className })}
            aria-labelledby={elementId}
            ref={buttonRef}
        >
            {cloneElement(children, {
                className: iconClassNames[variant].className.join(" "),
            })}

            <Typography tag="span" variant="label" id={elementId} className={`${!labelVisible ? "sr-only" : ""}`}>
                {label}
            </Typography>
        </button>
    );
};

const _IconButton = forwardRef(IconButton);

export { _IconButton as IconButton };
