import isEqual from "lodash/isEqual";
import { ChangeEvent, memo, ReactNode, useEffect, useMemo, useRef } from "react";
import { Control, Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import PeriodFilter from "@sol/filters/PeriodFilter";
import { Button } from "@sol/uikit";
import { Typography } from "@sol/uikit/core/Typography";

import CheckboxFilter from "./CheckboxFilter";
import ModalFilter from "./ModalFilter";
import NotPinnedFilters from "./NotPinnedFilters";
import { CheckboxFilterValues, FilterConfig, FilterType } from "./types";

type Props = {
    filters: FilterConfig[];
    onChange?: (value: CheckboxFilterValues | Record<string, any>) => void;
    value?: CheckboxFilterValues | Record<string, any>;
    showTitle?: boolean;
    buttonIcon?: JSX.Element;
};

const DEFAULT_VALUE: CheckboxFilterValues | Record<string, any> = {};

type ValuesWatcherProps = {
    control?: Control;
    onChange: (values: CheckboxFilterValues) => void;
};

/**
 * HACK: FormValuesWatcher component isolate form values change detections
 * from re-renders:
 * Just using useEffect(() => ..., [formValues])) will trigger
 * the function on each renders and lead to render loops.
 * To avoid this we wrap it inside a memoized component which
 * will rerender only on value change or prop change. Perhaps
 * an upgrade to the new watch subscribe API from react-hook-form
 * can solve the problem
 */

const FormValuesWatcher = memo(function FormValuesWatcher({ control, onChange }: ValuesWatcherProps) {
    const values = useWatch({ control });

    useEffect(() => {
        onChange(values);
    }, [values]);

    return null;
});

const FiltersList = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};

    flex-wrap: wrap;

    > ${Button} {
        margin: 0;
    }
`;

const FilterBar = ({
    filters,
    value: defaultValues = DEFAULT_VALUE,
    onChange: setDefaultValues,
    buttonIcon,
    showTitle = true,
    ...props
}: Props) => {
    const defaultValuesRef = useRef(defaultValues);
    const currentValuesRef = useRef(defaultValues);
    const setDefaultValuesRef = useRef(setDefaultValues);

    setDefaultValuesRef.current = setDefaultValues;
    currentValuesRef.current = defaultValues;

    const [t] = useTranslation();

    const notPinnedFields = useMemo(() => {
        const notPinnedFields: string[] = [];

        filters.forEach(filter => {
            switch (filter.type) {
                case FilterType.CHECKBOX:
                    filter.choices.forEach((choice, index) => {
                        if (!choice.pinned) {
                            notPinnedFields.push(`${filter.id}.${index}`);
                        }
                    });
                    break;
            }
        });

        return notPinnedFields;
    }, [filters]);

    const form = useForm({ defaultValues });
    const handleChange = useMemo(() => {
        return (values: CheckboxFilterValues | Record<string, any>) => {
            if (!isEqual(currentValuesRef.current, values)) {
                setDefaultValuesRef.current?.(values);
            }
        };
    }, []);

    useEffect(() => {
        if (!isEqual(defaultValuesRef.current, defaultValues)) {
            form.reset(defaultValues);
            defaultValuesRef.current = defaultValues;
        }
    }, [defaultValues]);

    const quickFilters = (() => {
        const result: ReactNode[] = [];

        filters.forEach(filter => {
            switch (filter.type) {
                case FilterType.CHECKBOX:
                    filter.choices.forEach((choice, index) => {
                        if (choice.pinned) {
                            const { value, label } = choice;
                            result.push(
                                <Controller
                                    control={form.control}
                                    key={`${filter.id}.${index}`}
                                    name={`${filter.id}.${index}`}
                                    defaultValue={null}
                                    render={({ field: { onChange, value: inputValue, ...inputProps } }) => {
                                        return (
                                            <CheckboxFilter
                                                {...inputProps}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    onChange(e?.target?.checked ? value : null)
                                                }
                                                checked={!!inputValue}
                                                value={value}
                                                title={`${t("component.filtersBar.labelPrefix")} ${label}`}
                                            >
                                                {label}
                                            </CheckboxFilter>
                                        );
                                    }}
                                />,
                            );
                        }
                    });
                    break;
                case FilterType.MODAL:
                    result.push(
                        <ModalFilter
                            key={filter.id}
                            title={filter.title}
                            label={filter.label}
                            count={filter.count}
                            defaultValues={defaultValues}
                            onSubmit={(data: CheckboxFilterValues) => {
                                handleChange({ ...defaultValues, ...data });
                            }}
                        >
                            {filter.render()}
                        </ModalFilter>,
                    );
                    break;
                case FilterType.PICKER:
                    result.push(
                        <ModalFilter
                            key={filter.id}
                            title={filter.title}
                            label={filter.label}
                            count={filter.count}
                            icon={filter.icon}
                            actions={filter.actions}
                            defaultValues={defaultValues}
                            showResetButton={filter.showResetButton}
                            onSubmit={data => {
                                handleChange({ ...defaultValues, ...data });
                            }}
                            onReset={() => {
                                form.reset({ ...defaultValues, [filter.id]: [] });
                            }}
                        >
                            {filter.render()}
                        </ModalFilter>,
                    );
                    break;
                case FilterType.PERIOD:
                    result.push(
                        <PeriodFilter
                            key={filter.id}
                            label={filter.label}
                            defaultValues={defaultValues}
                            icon={filter.icon}
                            onSubmit={data => {
                                handleChange({ ...defaultValues, ...data });
                            }}
                            onReset={() => {
                                form.reset({
                                    ...defaultValues,
                                    [filter.id]: { key: filter.id, startDate: null, endDate: null },
                                });
                            }}
                        >
                            {filter.render()}
                        </PeriodFilter>,
                    );
            }
        });

        return result;
    })();

    return (
        <div data-cy="filter-bar" {...props}>
            <FormValuesWatcher control={form.control} onChange={handleChange} />
            {showTitle && (
                <Typography variant="label" tag="h2">
                    {t("@sol.filters.FiltersBar.filters")}
                </Typography>
            )}

            <FiltersList>
                <FormProvider {...form}>
                    {quickFilters}
                    {notPinnedFields.map(field => (
                        <input key={field} {...form.register(field)} type="hidden" />
                    ))}
                </FormProvider>

                {notPinnedFields.length > 0 && (
                    <ModalFilter
                        defaultValues={defaultValues}
                        onSubmit={data => {
                            handleChange({ ...defaultValues, ...data });
                        }}
                        title={t("@sol.filters.FiltersModalHeader.filters")}
                        label={t("@sol.filters.FiltersBar.allFilters")}
                        count={(values: any) => {
                            let count = 0;

                            filters.forEach(filter => {
                                const { id, type } = filter;

                                if (type === FilterType.CHECKBOX) {
                                    count += (values?.[id] || []).filter((value: any) => !!value).length;
                                }
                            });

                            return count;
                        }}
                    >
                        <NotPinnedFilters filters={filters} />
                    </ModalFilter>
                )}
            </FiltersList>
        </div>
    );
};

export default styled(FilterBar)`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing[4]};
`;
