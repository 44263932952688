import clsx from "clsx";
import { TFunction } from "i18next";
import Image from "next/image";
import Link from "next/link";
import { ComponentPropsWithRef } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import { BriefStatus } from "src/enums";
import styled from "styled-components";

import { Feature, FeatureGuard } from "@sol/features";
import { Person, Users } from "@sol/icons";
import { interpolate, Route } from "@sol/routing";
import { CorrectionStatus, getPlaceholderPicture, getResourceUuid, IBriefItem, TopicStatus } from "@sol/sdk";
import { Tag } from "@sol/uikit";
import { Menu as MenuIcon } from "@sol/uikit/core/icons";
import { Typography } from "@sol/uikit/core/Typography";
import { IconButton } from "@sol/uikit/molecules/IconButton";

import { CARDS_COVER_WIDTH } from "../../../uikit/theme/imageSizes";
import UserBox from "../../shared/UserBox";
import BriefActionsMenu from "./BriefActionsMenu";

const StatusContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: ${({ theme }) => theme.spacing[5]};
    right: ${({ theme }) => theme.spacing[5]};
    z-index: ${({ theme }) => theme.zIndex.element};
    gap: ${({ theme }) => theme.spacing[3]};

    ${Tag} {
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.spacing[3]};

        --icon-color: ${({ theme }) => theme.palette.grey.base};

        > * {
            display: block;
        }
    }
`;

const CoeditorsIcon = styled(Users)`
    .colorStroke {
        stroke: ${({ theme }) => theme.palette.green.base};
    }
`;

const CountryFlag = styled.img`
    height: 16px;
    width: 16px;
`;

const renderBriefStatusComponent = (t: TFunction, brief: IBriefItem) => {
    const status = [];

    if (
        brief.topic?.status === TopicStatus.UNVALIDATED ||
        (brief.groupSpace && !brief.groupSpace.lastGroupWorkStatus)
    ) {
        const id = "work-to-submit";
        status.push(
            <Tag key={id} data-cy={id}>
                <Person size={16} />
                <p>{t("component.BriefCard.tags.workToSubmit")}</p>
            </Tag>,
        );
    }

    if (brief.status === BriefStatus.DRAFT) {
        const id = "draft";

        status.push(
            <Tag key={id} data-cy={id}>
                <p>{t("component.BriefCard.tags.draft")}</p>
            </Tag>,
        );
    }

    if (brief.correctionStatus === CorrectionStatus.WITHOUT_WORK) {
        const id = "without-work";

        status.push(
            <Tag key={id} data-cy={id}>
                <p>{t("component.BriefCard.tags.withoutWork")}</p>
            </Tag>,
        );
    }

    if (brief.correctionStatus === CorrectionStatus.TO_CORRECT) {
        const id = "to-correct";

        status.push(
            <Tag key={id} data-cy={id}>
                <p>{t("component.BriefCard.tags.workToCorrect")}</p>
            </Tag>,
        );
    }

    if (brief.coeditors.length > 0) {
        const id = "collaborative";

        status.push(
            <Tag key={id} data-cy={id} variant="success">
                <CoeditorsIcon size={16} />
                <p>{t("component.BriefCard.tags.coedited")}</p>
            </Tag>,
        );
    }

    return (
        <StatusContainer>
            {status}
            {brief.language && (
                <Tag data-cy="language">
                    <CountryFlag alt="" src={`/static/flags/${brief.language.locale.substring(0, 2)}.svg`} />
                    <p>{brief.language.locale.toUpperCase()}</p>
                </Tag>
            )}
        </StatusContainer>
    );
};

type Props = {
    brief: IBriefItem;
    className?: string;
} & Pick<ComponentPropsWithRef<typeof BriefActionsMenu>, "onAction">;

const BriefCard = ({ brief, onAction, className }: Props) => {
    const [t] = useTranslation();
    const titleId = useId();
    const { title, description, imageUrl, createdAt, createdBy, updatedAt, updatedBy, language } = brief;

    return (
        <article
            className={clsx(
                "h-[450px]",
                "flex flex-col overflow-hidden rounded-lg bg-white-base",
                "border-2 border-grey-lightest",
                className,
            )}
            data-cy="brief-card"
        >
            <div className={`relative h-[200px]`}>
                <div className="flex h-full object-cover">
                    <Image
                        src={imageUrl || getPlaceholderPicture(brief)}
                        layout="fixed"
                        objectFit="cover"
                        width={CARDS_COVER_WIDTH}
                        height={200}
                        alt=""
                    />
                </div>
                <div className="absolute left-0 top-0 z-10 flex h-full w-full">
                    <BriefActionsMenu
                        brief={brief}
                        button={
                            <IconButton
                                className="absolute right-4 top-4 z-50"
                                variant="secondary"
                                label={t("component.BriefCard.openMenu")}
                            >
                                <MenuIcon size={32} stroke="transparent-base" fill="purple-base" />
                            </IconButton>
                        }
                        onAction={onAction}
                        variant="light"
                    />
                    {renderBriefStatusComponent(t, brief)}
                </div>
            </div>

            <div className="flex flex-1 flex-col justify-between gap-3 p-6">
                <div lang={language?.locale}>
                    <Typography
                        tag="h2"
                        variant="h4"
                        className="inline-flex items-center p-1 transition-all duration-100 ease-in-out hover:text-purple-base"
                        data-cy="brief-card-title"
                        id={titleId}
                    >
                        <Link href={interpolate(Route.BRIEFS_DETAILS, { id: getResourceUuid(brief) })} passHref>
                            <a className="focus-classes line-clamp-2 rounded-md outline-none">{title}</a>
                        </Link>
                    </Typography>
                    <Typography className="line-clamp-4 p-1">{description}</Typography>
                </div>

                <FeatureGuard feature={Feature.BRIEFS_CREATE}>
                    <div className="flex items-center justify-between gap-2">
                        <UserBox className="w-1/2" type="createdAt" date={createdAt} user={createdBy} />
                        {updatedBy && <UserBox className="w-1/2" type="updatedAt" date={updatedAt} user={updatedBy} />}
                    </div>
                </FeatureGuard>
            </div>
        </article>
    );
};

export default styled(BriefCard)``;
