import { ReactNode } from "react";

export enum FilterType {
    CHECKBOX = "checkbox",
    MODAL = "modal",
    PICKER = "picker",
    PERIOD = "period",
}

export type BaseFilterConfig = {
    id: string;
    label: string;
    type: FilterType;
};

export type ModalFilterOptions = {
    icon?: JSX.Element;
};

export type CheckboxFilterConfig = BaseFilterConfig & {
    type: FilterType.CHECKBOX;
    choices: {
        value: string;
        label: string;
        pinned?: boolean;
    }[];
};

export type CheckboxFilterValues = Record<string, (string | null)[]>;

export type ModalFilterConfig = BaseFilterConfig & {
    type: FilterType.MODAL;
    title: string;
    count?: (values: CheckboxFilterValues) => number;
    render: () => ReactNode;
    options?: ModalFilterOptions;
};

export type PickerFilterConfig = BaseFilterConfig & {
    type: FilterType.PICKER;
    title: string;
    count?: (values: CheckboxFilterValues) => number;
    render: () => ReactNode;
    options?: ModalFilterOptions;
    icon?: JSX.Element;
    actions?: () => ReactNode;
    showResetButton?: boolean;
};

export type PeriodFilterConfig = BaseFilterConfig & {
    type: FilterType.PERIOD;
    icon?: JSX.Element;
    render: () => ReactNode;
};

export type FilterConfig = CheckboxFilterConfig | ModalFilterConfig | PickerFilterConfig | PeriodFilterConfig;
