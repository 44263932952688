import { format, isEqual } from "date-fns";
import { enUS, es, fr } from "date-fns/locale";
import { motion } from "framer-motion";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Retry, Cross } from "@sol/uikit/core/icons";
import { Button } from "@sol/uikit/molecules/Button";
import { GhostButton } from "@sol/uikit/molecules/GhostButton";

import i18n from "../../i18n";
import { ModalFilterOptions } from "./types";

const Popup = styled(motion.div)`
    position: fixed;
    max-width: 1044px;
    width: 100%;
    padding: ${({ theme }) => theme.spacing[5]};
    z-index: ${({ theme }) => theme.zIndex.overlay};
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    overflow: hidden;
    background: ${({ theme }) => theme.palette.white.base};
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing[2]};
`;

type Props = {
    className?: string;
    defaultValues?: Record<string, any>;
    onCancel?: () => void;
    onReset: () => void;
    onSubmit: (values: Record<string, { startDate: Date; endDate: Date }>) => void;
    children: ReactNode | (() => ReactNode);
    label: string;
} & ModalFilterOptions;

const SUPPORTED_LANGUAGES: { [key: string]: { locale: Locale; format: string } } = {
    en: { locale: enUS, format: "yyyy/MM/dd" },
    fr: { locale: fr, format: "dd/MM/yyyy" },
    es: { locale: es, format: "dd/MM/yyyy" },
};

const PeriodFilter = ({ className, onSubmit, onCancel, onReset, defaultValues = {}, children, label, icon }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const openButtonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const [t] = useTranslation();
    const [open, setOpen] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const form = useForm({ defaultValues });
    const language = SUPPORTED_LANGUAGES[i18n.language] ?? SUPPORTED_LANGUAGES["fr"];

    useEffect(() => {
        form.reset(defaultValues);
    }, [defaultValues]);

    const values = form.watch();

    const handleFormSubmit = useMemo(() => {
        return form.handleSubmit(values => {
            onSubmit(values);
            setOpen(false);
            openButtonRef?.current?.focus();
        });
    }, [form.handleSubmit, setOpen, onSubmit]);

    const hasSelectedPeriod = () => {
        const { startDate, endDate } = values.period ?? {};
        if (!endDate) {
            return false;
        }

        return !isEqual(startDate, endDate);
    };

    const triggerButtonClasses = hasSelectedPeriod() ? "!bg-purple-base-lighter" : "!bg-purple-white-base";

    const calculatePopupPosition = () => {
        const buttonRect = containerRef.current?.getBoundingClientRect();
        const popupRect = popupRef.current?.getBoundingClientRect();

        if (buttonRect && popupRect) {
            const offsetRight = popupRect.width;
            const offsetTop = 21; // Got this value from the figma 25px - 4px (of button margin);

            setPopupPosition({
                top: buttonRect.bottom + window.scrollY + offsetTop,
                left: buttonRect.right + window.scrollX - offsetRight,
            });
        }
    };

    useEffect(() => {
        if (open) {
            popupRef?.current?.focus();
            calculatePopupPosition();
        }
    }, [open]);

    return (
        <div ref={containerRef} className={className}>
            <Button
                data-cy="open-modal-filter"
                onPress={() => setOpen(!open)}
                variant="secondary"
                className={triggerButtonClasses}
            >
                {hasSelectedPeriod()
                    ? `${format(values.period?.startDate, language.format)} - ${format(
                          values.period?.endDate,
                          language.format,
                      )}`
                    : label}
                {!hasSelectedPeriod() && icon && icon}
            </Button>
            <FormProvider {...form}>
                {open && (
                    <Popup
                        ref={popupRef}
                        tabIndex={0}
                        data-cy="period-filter"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        style={{ top: popupPosition.top, left: popupPosition.left }}
                    >
                        <form onSubmit={handleFormSubmit}>
                            {children}
                            <Footer>
                                <Button
                                    variant="primary"
                                    type="button"
                                    data-cy="reset-period"
                                    onPress={() => {
                                        setOpen(false);
                                        onReset();
                                    }}
                                >
                                    <Retry stroke="white-base" fill="transparent-base" />
                                    {t("@sol.filters.FiltersPeriod.delete")}
                                </Button>
                                <div className="flex items-center gap-1">
                                    <GhostButton
                                        variant="primary"
                                        onPress={() => {
                                            setOpen(false);
                                            onCancel?.();
                                            openButtonRef?.current?.focus();
                                        }}
                                    >
                                        {t("@sol.filters.FiltersPeriod.cancel")}
                                        <Cross stroke="purple-base" fill="purple-base" />
                                    </GhostButton>
                                    <Button data-cy="valid-period" type="submit" variant="primary">
                                        {t("@sol.filters.FiltersPeriod.submit")}
                                    </Button>
                                </div>
                            </Footer>
                        </form>
                    </Popup>
                )}
            </FormProvider>
        </div>
    );
};

export default styled(PeriodFilter)``;
