import React, { SyntheticEvent } from "react";
import styled from "styled-components";

import { CheckboxFilter } from "@sol/filters";
import { useLanguages } from "@sol/sdk";

import { Text } from "../../../uikit";
import { BlockSkeleton } from "../../skeletons";

const CountryFlag = styled.img`
    height: 24px;
    width: 24px;
`;

const LabelWithFlag = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: ${({ theme }) => theme.spacing[4]};
`;

type Props = {
    className?: string;
    value: string[];
    onChange: (value: string[]) => void;
};

const LanguagesPicker = ({ value, onChange, ...props }: Props) => {
    const { data, isLoading } = useLanguages({});

    if (isLoading) {
        return (
            <div className="h-48 !flex-none">
                <BlockSkeleton />
            </div>
        );
    }

    return (
        <div {...props}>
            {data?.["hydra:member"].map(({ "@id": id, title, locale }) => {
                const checked = value?.includes(id);
                return (
                    <CheckboxFilter
                        key={id}
                        onChange={() => {
                            if (checked) {
                                // remove the language
                                onChange(value.filter(languageId => languageId !== id));
                            } else {
                                // add the language
                                onChange([...value, id]);
                            }
                        }}
                        checked={checked}
                    >
                        <LabelWithFlag>
                            <Text lines={1} lang={locale}>
                                {title}
                            </Text>
                            <CountryFlag
                                alt=""
                                src={`/static/flags/${locale.substring(0, 2)}.svg`}
                                onError={(e: SyntheticEvent<HTMLImageElement, Event>) =>
                                    (e.currentTarget.style.display = "none")
                                }
                            />
                        </LabelWithFlag>
                    </CheckboxFilter>
                );
            })}
        </div>
    );
};

export default styled(LanguagesPicker)`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: ${({ theme }) => theme.spacing[4]};
`;
