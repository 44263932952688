import { AxiosRequestConfig } from "axios";

import { IBrief, IBriefResource, IClassroomResource } from "@sol/sdk";
import { createApiQueryHook } from "@sol/sdk/utils/api";
import { RelationalValue, mapRelationalValue } from "@sol/sdk/utils/filters";

export type IBriefQueryParameters = {
    brief?: RelationalValue<IBriefResource>;
    classroom?: RelationalValue<IClassroomResource>;
};

export type IBriefQueryResult = IBrief;

export function briefQuery({ brief, classroom }: IBriefQueryParameters): AxiosRequestConfig | undefined {
    if (!brief) {
        return;
    }

    return {
        method: "GET",
        url: `/briefs/${mapRelationalValue(brief)}`,
        headers: classroom && { "x-classroom-uuid": mapRelationalValue(classroom) },
    };
}

export const useBriefQuery = createApiQueryHook<IBriefQueryParameters, IBriefQueryResult>(briefQuery);
