import React from "react";
import { useTranslation } from "react-i18next";

import { useActiveClassroom } from "@sol/classrooms";
import { useCheckFeatureFlag, Feature } from "@sol/features";
import { AssignationType, BriefStatus } from "@sol/sdk";

import BriefsView from "../BriefListView";
import { BriefsViewFilter, useFilters } from "../BriefListView/filters";

const BriefsAssigned = () => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListPage.BriefsAssigned" });
    const { activeClassroom } = useActiveClassroom();
    const checkFeatureFlag = useCheckFeatureFlag();

    const viewFilters = [];

    const canEvaluate = checkFeatureFlag([Feature.TOPICS_WORK_EVAL, Feature.WORKSPACE_GROUP_CORRECTION]);

    if (canEvaluate) {
        viewFilters.push({ viewFilter: BriefsViewFilter.CORRECTIONS_STATUS, pinned: true });
    }

    viewFilters.push({
        viewFilter: BriefsViewFilter.ASSIGNATION_TYPE,
        backlist: [AssignationType.NONE],
        pinned: !canEvaluate,
    });

    if (checkFeatureFlag(Feature.BRIEFS_CREATE)) {
        viewFilters.push({ viewFilter: BriefsViewFilter.CREATOR_TYPE });
    }

    const filters = useFilters(viewFilters, [checkFeatureFlag]);

    return (
        <BriefsView
            title={t("title")}
            welcomeMessage={t("welcomeMessage", {
                promo: activeClassroom?.title,
            })}
            filters={filters}
            queryFilters={queryFilters => ({
                ...queryFilters,
                assignedClassroom: activeClassroom,
                // Only show published briefs
                status: BriefStatus.VALIDATED,
            })}
        />
    );
};

export default BriefsAssigned;
