import { ComponentPropsWithoutRef, ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import Modal from "@sol/modal/Modal";

import CheckboxFilter from "./CheckboxFilter";
import FilterModalFilter from "./FiltersModalFilter";
import { FilterConfig, FilterType } from "./types";

const CheckboxFilters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing[4]};
`;

type Props = ComponentPropsWithoutRef<typeof Modal> & {
    filters: FilterConfig[];
};

const NotPinnedFilters = ({ filters, ...props }: Props) => {
    return (
        <div {...props}>
            {filters.map(filter => {
                const { id, label, type } = filter;

                switch (type) {
                    case FilterType.CHECKBOX:
                        return (
                            <FilterModalFilter key={id} label={label}>
                                <CheckboxFilters>
                                    {filter.choices.map(({ value, label }, index) => {
                                        return (
                                            <Controller
                                                key={value}
                                                name={`${id}.${index}`}
                                                defaultValue={null}
                                                render={({ field: { onChange, value: inputValue, ...inputProps } }) => {
                                                    return (
                                                        <CheckboxFilter
                                                            {...inputProps}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                onChange(e?.target?.checked ? value : null)
                                                            }
                                                            checked={!!inputValue}
                                                            value={value}
                                                        >
                                                            {label}
                                                        </CheckboxFilter>
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                </CheckboxFilters>
                            </FilterModalFilter>
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default styled(NotPinnedFilters)`
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: ${({ theme }) => theme.spacing[4]};
`;
