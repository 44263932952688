import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { getUserDisplayName, IUserResource } from "@sol/sdk";

import UserAvatar from "./UserAvatar";

type AuthorBoxProps = {
    user: IUserResource;
    type: "createdAt" | "updatedAt";
    date: string;
    className?: string;
};

const UserBox = styled(({ user, type, date, className }: AuthorBoxProps) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <UserAvatar user={user} size="small" />
            <div>
                <Text variant="subheading" lines={2} title={getUserDisplayName(user)}>
                    {getUserDisplayName(user)}
                </Text>
                <Text variant="subheading">
                    {t(`component.BriefCard.${type}`, { date: format(new Date(date), "dd/MM/yy") })}
                </Text>
            </div>
        </div>
    );
})`
    display: flex;
    align-items: center;

    ${Text} + ${Text} {
        color: ${({ theme }) => theme.palette.grey.base};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 110px;
    }

    > ${UserAvatar} {
        min-width: 32px;
        margin-right: ${({ theme }) => theme.spacing[3]};
    }
`;

export default UserBox;
