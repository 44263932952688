import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationLevel, useNotify } from "@sol/notifications";
import { IUserList, IUserResource } from "@sol/sdk";
import { useBriefQuery } from "@sol/sdk/briefs/queries/briefQuery";
import { useCheckIsTrainer } from "@sol/sdk/briefs/useCheckIsTrainer";
import { useUpdateCoeditors } from "@sol/sdk/briefs/useUpdateCoeditors";

import { UserPicker } from "./UserPicker";

type CoeditorEmail = string;

type AddCoeditorVariables = {
    email: CoeditorEmail;
};

type Props = {
    briefId: string;
    // onClose: () => void;
    onCancel: () => void;
    onSubmit: () => void;
    className?: string;
};

const CoeditorsPicker = ({ briefId, onCancel, onSubmit }: Props) => {
    const [t] = useTranslation();
    const notify = useNotify();

    const [coeditors, setCoeditors] = useState<IUserResource[]>([]);
    const [emailToCheck, setEmailToCheck] = useState("");
    const [hasEditCoeditors, setHasEditCoeditors] = useState(false);

    const { data: currentBrief, isLoading: isBriefLoading, error: briefError } = useBriefQuery({ brief: briefId });

    useEffect(() => {
        if (currentBrief?.coeditors) {
            setCoeditors(currentBrief.coeditors);
        }
    }, [currentBrief]);

    const onEmailCheckSuccess = (data: IUserList) => {
        const newCoeditor = data["hydra:member"][0];

        setCoeditors([...coeditors.filter(coeditor => coeditor.email !== newCoeditor.email), newCoeditor]);
        setHasEditCoeditors(true);
    };

    // Checks that the given email belongs to a trainer
    const {
        refetch: refetch,
        isLoading: isEmailCheckingLoading,
        error,
    } = useCheckIsTrainer({
        email: emailToCheck,
        onSuccess: onEmailCheckSuccess,
    });

    const [mutate, { isLoading: isMutationLoading, reset, error: mutationError }] = useUpdateCoeditors({
        onSuccess: data => {
            const {
                data: { coeditors },
            } = data;

            onMutationSuccess(coeditors);
        },
    });

    const onMutationSuccess = (coeditors: IUserResource[]) => {
        setCoeditors(coeditors);
        onSubmit();

        notify({
            level: NotificationLevel.SUCCESS,
            message: t("component.CoeditorsPicker.confirmationMessage", { count: coeditors.length }),
        });
    };

    const handleUserAdd = ({ email }: AddCoeditorVariables) => {
        setEmailToCheck(email);
        refetch();
    };

    const handleCoeditorsListSubmit = () => {
        mutate({ payload: coeditors.map(coeditor => coeditor["@id"]), briefUuid: briefId });
    };

    const handleUserRemove = (coeditorEmail: CoeditorEmail) => {
        setCoeditors(coeditors.filter(({ email }) => email !== coeditorEmail));
        setHasEditCoeditors(true);
    };

    return (
        <UserPicker
            title={t("component.CoeditorsPicker.formTitle")}
            onUserAdd={handleUserAdd}
            showEmailOnly
            emailCheckingApiError={error?.response?.data?.["hydra:description"]}
            emailCheckingLoading={isEmailCheckingLoading}
            users={coeditors as IUserResource[]}
            usersListTitle={t("component.CoeditorsPicker.listTitle")}
            noUserMessage={t("component.CoeditorsPicker.noCoeditorYet")}
            onUserRemove={handleUserRemove}
            isLoading={isBriefLoading}
            onClose={onCancel}
            onSubmit={handleCoeditorsListSubmit}
            isSubmitting={isMutationLoading}
            disabledSubmit={!hasEditCoeditors}
            apiErrorsReset={reset}
            apiErrors={briefError || mutationError}
            data-cy="coeditors-picker"
        />
    );
};

export default CoeditorsPicker;
