import React, { FormEvent, DetailedHTMLProps, FormHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { SROnly } from "src/uikit";
import styled from "styled-components";
import { Merge } from "type-fest";

import { Cross, Magnifier } from "@sol/icons";
import { IconButton, InputDecorator, TextInput } from "@sol/uikit";
import { IconButtonVariant } from "@sol/uikit/buttons/IconButton";

const SubmitButton = styled(IconButton)``;

const ClearableInputBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    ${TextInput} {
        padding-right: calc(
            ${({ theme }) => theme.spacing[3]} + 32px /* reset button width */ + ${({ theme }) => theme.spacing[3]}
        );
    }
`;

const ResetButton = styled(IconButton)`
    position: absolute;
    right: ${({ theme }) => theme.spacing[3]};
    top: ${({ theme }) => theme.spacing[3]};
    --icon-color: ${({ theme }) => theme.palette.grey.base};
`;

type Props = Merge<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    {
        searchFieldId: string;
        onSubmit: () => void;
        label: string;
        value: string;
        onChange: (value: string) => void;
        onReset: () => void;
        srOnly: string;
        variant?: IconButtonVariant;
        resetButtonDescribedBy?: string;
    }
>;

const SearchForm = ({
    searchFieldId,
    onSubmit,
    label,
    value,
    onChange,
    onReset,
    srOnly,
    variant,
    resetButtonDescribedBy,
    ...props
}: Props) => {
    const [t] = useTranslation();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <form data-cy={`${searchFieldId}-form`} onSubmit={handleSubmit} {...props}>
            <InputDecorator label={label} small htmlFor={searchFieldId}>
                {() => (
                    <ClearableInputBox>
                        <TextInput
                            id={searchFieldId}
                            type="text"
                            value={value}
                            onChange={e => onChange(e.currentTarget.value)}
                        />
                        {value.length > 0 && (
                            <ResetButton
                                type="reset"
                                onClick={onReset}
                                data-cy="reset-search-form"
                                aria-describedby={resetButtonDescribedBy}
                            >
                                <Cross />
                                <SROnly>{t("component.SearchForm.resetSrOnly")}</SROnly>
                            </ResetButton>
                        )}
                    </ClearableInputBox>
                )}
            </InputDecorator>

            <SubmitButton type="submit" variant={variant} size="large" data-cy="submit-search-form">
                <SROnly>{srOnly}</SROnly>
                <Magnifier />
            </SubmitButton>
        </form>
    );
};

export default styled(SearchForm)`
    display: flex;
    align-items: flex-end;

    gap: ${({ theme }) => theme.spacing[3]};
    ${TextInput} {
        width: 295px;
    }
`;
