import React from "react";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUser } from "@sol/authentication";

import BriefsView from "../BriefListView";
import { BriefsViewFilter, useFilters } from "../BriefListView/filters";

const BriefsCollaborate = () => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListPage.BriefsCollaborate" });
    const { user } = useAuthenticatedUser();

    const filters = useFilters(
        [
            { viewFilter: BriefsViewFilter.STATUS },
            { viewFilter: BriefsViewFilter.ASSIGNATION_TYPE },
            { viewFilter: BriefsViewFilter.CREATOR_TYPE, pinned: true },
        ],
        [],
    );

    return (
        <BriefsView
            title={t("title")}
            welcomeMessage={t("welcomeMessage")}
            filters={filters}
            queryFilters={queryFilters => ({
                ...queryFilters,
                coeditors: user,
            })}
        />
    );
};

export default BriefsCollaborate;
