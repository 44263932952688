import { motion } from "framer-motion";
import React, { ComponentPropsWithoutRef, FC, ReactNode } from "react";
import styled, { css } from "styled-components";

import { SvgIcon } from "@sol/icons";

import Text from "../Text";
import theme from "../theme";

type Props = ComponentPropsWithoutRef<"button"> & {
    icon?: ReactNode;
    isSelected: boolean;
    orientation?: "vertical" | "horizontal";
    describedBy?: string;
};

export const SelectedIndicator = styled.span`
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
`;

const Content = styled.span`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    > ${SvgIcon} {
        height: 16px;
        width: 16px;
    }

    > ${SvgIcon} + ${Text} {
        margin-top: ${({ theme }) => theme.spacing[2]};
    }

    > ${Text} {
        user-select: none;
    }
`;

const SegmentedControlsItem: FC<Props> = ({ icon, children, isSelected, orientation, describedBy, ...rest }) => (
    <button
        type="button"
        data-cy="segmented-control-item"
        aria-current={isSelected}
        aria-describedby={describedBy}
        {...rest}
    >
        {isSelected && (
            <SelectedIndicator
                as={motion.span}
                layoutId="indicator"
                initial={false}
                transition={theme.transitions.spring.stiff}
            />
        )}
        <Content>
            {icon}
            <Text as="span" variant="subheading" lines={2}>
                {children}
            </Text>
        </Content>
    </button>
);

export default styled(SegmentedControlsItem)`
    ${({ theme, isSelected, orientation }) => {
        const color = (c: string) => css`
            color: ${c};
            --text-color: ${c};
            --icon-color: ${c};
        `;

        return css`
            min-height: ${orientation === "vertical" ? "72px" : 0};
            flex: 1;
            position: relative;
            text-align: center;
            padding: ${orientation === "vertical"
                ? `${theme.spacing[4]} ${theme.spacing[2]}`
                : `${theme.spacing[3]} ${theme.spacing[5]}`};
            border: 0;
            background-color: transparent;
            border-radius: ${({ theme }) => theme.shape.borderRadius.small};

            transition: box-shadow 0.2s;
            will-change: box-shadow;

            outline: 0;
            cursor: pointer;

            ${({ theme }) => color(isSelected ? theme.palette.purple.base : theme.palette.grey.base)};
            font-weight: ${isSelected ? 700 : 600};

            ${SelectedIndicator} {
                background-color: ${({ theme }) => theme.palette.purple.lightest};
                border: 2px solid ${({ theme }) => theme.palette.purple.base};
                z-index: ${({ theme }) => theme.zIndex.navigation};
            }

            ${Content} {
                z-index: ${theme.zIndex.navigation + 1};
            }

            :focus {
                outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
                outline-offset: 2px;
            }

            :hover,
            :active {
                ${({ theme }) => !isSelected && color(theme.palette.purple.light)};
            }

            :active {
                font-weight: 700;
            }
        `;
    }}
`;
