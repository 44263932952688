import React, { Children, ReactElement, ReactNode } from "react";

type Props<T = string> = {
    onChange: (value: T) => void;
    name: string;
    value: T;
    children?: ReactNode;
};

function RadioGroup<T = string>({ children, onChange, name, value }: Props<T>): ReactElement {
    return (
        <>
            {Children.map(children, child => {
                const childElement = child as ReactElement;
                const childValue = childElement.props.value as T;

                return React.cloneElement(childElement, {
                    // DEBT react-object-usage : Use React.cloneElement() or directly imported cloneElement() ?
                    onChange: () => onChange(childValue),
                    name,
                    checked: value === childValue,
                });
            })}
        </>
    );
}

export default RadioGroup;
