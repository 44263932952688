import { useMemo, FC } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import Text from "../../uikit/Text";

const Title = styled(Text).attrs({ variant: "label" })`
    line-height: 48px; // match filter height
`;

const Content = styled.div``;

type Props = {
    label: string;
};

const FiltersModalFilter: FC<Props> = ({ label, children }) => {
    const filterId = useMemo(() => uuid(), []);

    return (
        <>
            <Title id={filterId}>{label}</Title>
            <Content aria-labelledby={filterId}>{children}</Content>
        </>
    );
};

export default FiltersModalFilter;
