import React, { useState } from "react";
import { PressEvent, useId } from "react-aria";
import { useTranslation } from "react-i18next";
import UserAvatar from "src/components/shared/UserAvatar";
import { Text } from "src/uikit";
import styled from "styled-components";

import { IUserResource } from "@sol/sdk";
import { Minus, Plus } from "@sol/uikit/core/icons";
import { IconButton } from "@sol/uikit/molecules/IconButton";
import Tooltip from "@sol/uikit/Tooltip";

const Name = styled(Text).attrs({ variant: "label" })`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Email = styled(Text).attrs({ variant: "subheading", as: "span" })`
    color: ${({ theme }) => theme.palette.grey.base};
    flex: 1;
    text-align: right;
`;

const MAP_ACTION_TYPE_TO_ICON = {
    add: <Plus stroke="purple-base" />,
    remove: <Minus stroke="purple-base" />,
};

type Props = {
    user: IUserResource;
    small?: boolean;
    className?: string;
    onClick?: (e: PressEvent) => void;
    actionType?: "add" | "remove";
    disabledMessage?: string;
};

const UserRow = ({ user, small, onClick, className, disabledMessage, actionType = "add" }: Props) => {
    const [t] = useTranslation();

    const [display, setDisplay] = useState(false);

    const usernameContainerId = useId();

    const iconButton = (
        <IconButton
            data-cy="user-row-button"
            type="button"
            onPress={onClick}
            isDisabled={!!disabledMessage}
            label={t(`component.UserRow.srOnly.${actionType}`)}
            labelVisible={false}
            className="bg-grey-lightest"
            variant="primary"
            aria-describedby={usernameContainerId}
        >
            {MAP_ACTION_TYPE_TO_ICON[actionType]}
        </IconButton>
    );

    return (
        <div data-cy="user-row" className={className}>
            {disabledMessage ? (
                <Tooltip
                    text={disabledMessage}
                    type="error"
                    variant="primary"
                    position="left"
                    displayTooltip={display}
                    onMouseEnter={() => setDisplay(true)}
                    onMouseLeave={() => setDisplay(false)}
                >
                    {iconButton}
                </Tooltip>
            ) : (
                iconButton
            )}
            <UserAvatar user={user} />
            <Name id={usernameContainerId}>
                <span>
                    {user.name} {user.surname?.toUpperCase()}
                </span>
                {small && <Email>{user.email}</Email>}
            </Name>
            {!small && <Email>{user.email}</Email>}
        </div>
    );
};

export default styled(UserRow)<Pick<Props, "small">>`
    display: flex;
    align-items: center;
    height: 80px;
    padding: ${({ theme }) => theme.spacing[5]};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    background-color: ${({ theme }) => theme.palette.white.base};

    ${UserAvatar} {
        margin: ${({ theme }) => `0 ${theme.spacing[4]} 0 ${theme.spacing[6]}`};
        flex-shrink: 0;
    }
`;
