// We should use .js here as we need to import it from next.config.js

const REF_SCREEN = 1280;

const AVATAR_WIDTH = 48;
const CARDS_COVER_WIDTH = Math.round(REF_SCREEN / 3);
const CLASSROOM_CARD_COVER_WIDTH = Math.round(REF_SCREEN / 2);

const IMAGE_SIZES = [AVATAR_WIDTH, CARDS_COVER_WIDTH, CLASSROOM_CARD_COVER_WIDTH];

module.exports = {
    REF_SCREEN,
    AVATAR_WIDTH,
    CARDS_COVER_WIDTH,
    CLASSROOM_CARD_COVER_WIDTH,
    IMAGE_SIZES,
};
