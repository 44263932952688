import Link from "next/link";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { FeatureGuard, Feature } from "@sol/features";
import { FiltersBar, FilterConfig, CheckboxFilterValues } from "@sol/filters";
import { Brief } from "@sol/icons";
import { useNavigationState } from "@sol/persistance";
import { Route } from "@sol/routing";
import { BriefStatus, IClassroom, AssignationType, useBriefs, CorrectionStatus } from "@sol/sdk";
import { Button } from "@sol/uikit";

import SearchForm from "../../shared/SearchForm";
import WelcomeUser from "../../shared/WelcomeUser";
import BriefsResults, { BRIEF_CARDS_PER_PAGE, SortBrief, SORT_BRIEF_OPTIONS } from "./BriefsResults";
import { BriefsViewFilter } from "./filters";

const BriefsHeadingRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

type BriefQueryFilters = Parameters<typeof useBriefs>[0]["filters"];

type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T;

const filterTruthy = function <T>(values?: T[]) {
    return (values ?? []).filter(value => !!value) as Truthy<T>[];
};

const mapFiltersValueToFilters = (filtersValue: CheckboxFilterValues, activeClassroom?: IClassroom) => {
    const status = filterTruthy(filtersValue?.[BriefsViewFilter.STATUS]);
    const assignationType = filterTruthy(filtersValue?.[BriefsViewFilter.ASSIGNATION_TYPE]);
    const correctionStatus = filterTruthy(filtersValue?.[BriefsViewFilter.CORRECTIONS_STATUS]);
    const creatorType = filterTruthy(filtersValue?.[BriefsViewFilter.CREATOR_TYPE]);

    const frameworks = filterTruthy(filtersValue?.[BriefsViewFilter.FRAMEWORKS]);
    const skills = filterTruthy(filtersValue?.[BriefsViewFilter.SKILLS]);
    const tags = filterTruthy(filtersValue?.[BriefsViewFilter.TAGS]);
    const languages = filterTruthy(filtersValue?.[BriefsViewFilter.LANGUAGES]);

    const createdByMe = creatorType.includes("me");
    const createdByOthers = creatorType.includes("others");

    const enableActiveClassroom = assignationType.length > 0 || correctionStatus.length > 0;

    return {
        status: status.length > 0 ? (status as BriefStatus[]) : [BriefStatus.DRAFT, BriefStatus.VALIDATED],
        correctionStatus: correctionStatus as CorrectionStatus[],
        activeClassroom: enableActiveClassroom ? activeClassroom : undefined,
        assignationType: assignationType as AssignationType[],
        createdByConnectedUser: createdByMe === createdByOthers ? undefined : createdByMe,

        frameworks,
        skills: skills.filter(id => id?.startsWith("/skills/")),
        skillLevels: skills.filter(id => id?.startsWith("/skill_levels/")),
        tags,
        languages,
    };
};

const defaultQueryFilters = (filters: BriefQueryFilters) => {
    return filters;
};

type Props = {
    className?: string;
    title: string;
    welcomeMessage: string;
    filters: FilterConfig[];
    queryFilters?: (filters: BriefQueryFilters) => BriefQueryFilters;
};

const BriefsView = ({ className, title, welcomeMessage, filters, queryFilters = defaultQueryFilters }: Props) => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListView" });
    const { activeClassroom } = useActiveClassroom();
    const { user } = useAuthenticatedUser();

    const [currentPage, setCurrentPage] = useNavigationState<number>("page", 1);
    const [search, setSearch] = useNavigationState<string>("search", "");
    const [activeFilters, setActiveFilters] = useNavigationState<CheckboxFilterValues>("filters", {});
    const [sort, setSort] = useNavigationState<SortBrief>("sort", SORT_BRIEF_OPTIONS[0]);

    const [searchValue, setSearchValue] = useState(search);

    useEffect(() => {
        setCurrentPage(1);
    }, [search, activeFilters]);

    const {
        data: allBriefs,
        refetch: fetchBriefs,
        isLoading,
    } = useBriefs({
        filters: queryFilters({
            ...mapFiltersValueToFilters(activeFilters, activeClassroom),
            title: !!search.trim() ? search : undefined,
        }),
        pagination: {
            perPage: BRIEF_CARDS_PER_PAGE,
            page: currentPage,
        },
        ...sort,
        enabled: activeClassroom,
    });

    return (
        <div className={className}>
            <BriefsHeadingRow>
                <Text as="h1" variant="h3">
                    {title}
                </Text>
                <WelcomeUser user={user} message={welcomeMessage} />
            </BriefsHeadingRow>
            <BriefsHeadingRow>
                <SearchForm
                    searchFieldId="search"
                    onSubmit={() => setSearch(searchValue)}
                    onReset={() => {
                        setSearch("");
                        setSearchValue("");
                    }}
                    label={t("search.label")}
                    value={searchValue}
                    onChange={setSearchValue}
                    srOnly={t("search.sr-only")}
                    variant="secondary"
                />

                <FeatureGuard feature={Feature.BRIEFS_CREATE}>
                    <Link href={Route.BRIEFS_CREATE}>
                        <Button as="a" variant="primary" filled tabIndex={0}>
                            {t("createBrief")}
                            <Brief />
                        </Button>
                    </Link>
                </FeatureGuard>
            </BriefsHeadingRow>

            {(filters?.length ?? 0) > 0 && (
                <FiltersBar value={activeFilters} onChange={setActiveFilters} filters={filters} />
            )}
            <BriefsResults
                allBriefs={allBriefs}
                fetchBriefs={fetchBriefs}
                isLoading={isLoading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sort={sort}
                setSort={setSort}
            />
        </div>
    );
};

export default styled(BriefsView)`
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }

    ${BriefsHeadingRow} + ${BriefsHeadingRow} {
        > ${Button} {
            align-self: flex-end;
            margin: 0;
        }
    }

    ${BriefsResults} {
        margin-top: ${({ theme }) => theme.spacing[7]};
    }
`;
