import styled, { css, DefaultTheme } from "styled-components";

type PageButtonProps = {
    variant: "primary" | "secondary";
};

const style = ({ theme, variant }: { theme: DefaultTheme } & PageButtonProps) => {
    const primaryColor = theme.palette.purple.base;
    const neutralColor = variant === "secondary" ? theme.palette.white.base : theme.palette.grey.lightest;
    const hoverColor = theme.palette.yellow.base;
    const typography = theme.typography.subheading;

    return css`
        ${typography}
        color: ${variant === "secondary" ? theme.palette.grey.base : primaryColor};

        width: 2em;
        height: 2em;

        background-color: ${neutralColor};

        padding: 0;
        border: 0;
        border-radius: 50%;

        cursor: pointer;

        &:focus {
            outline: 3px solid ${({ theme }) => theme.palette.blue.focus};
            outline-offset: 2px;
        }

        &:not(:disabled):hover {
            background-color: ${hoverColor};
            color: ${primaryColor};
        }

        &:active {
            border: 2px solid ${primaryColor};
        }

        &:disabled {
            &:not([aria-current="true"]) {
                opacity: 0.6;
            }

            cursor: default;
        }

        &[aria-current="true"] {
            background-color: ${primaryColor};
            color: ${neutralColor};
        }
    `;
};

const PageButton = styled.button<PageButtonProps>`
    ${style}
`;

export default PageButton;
