import isNumber from "lodash/isNumber";
import { useMemo, useEffect, ReactNode } from "react";
import { useId } from "react-aria";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { GridColumn } from "@sol/uikit";
import { Typography } from "@sol/uikit/core/Typography";
import { Button } from "@sol/uikit/molecules/Button";
import { ModalTrigger, Dialog } from "@sol/uikit/organisms/ModalTrigger";

import FiltersModalHeader from "./FiltersModalHeader";
import { CheckboxFilterValues, ModalFilterOptions } from "./types";

const Actions = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[5]};
`;

const Body = styled(GridColumn)`
    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    box-shadow: 4px 4px 0 ${({ theme }) => theme.palette.purple.base};
    padding: ${({ theme }) => theme.spacing[7]};
`;

type Props = {
    className?: string;
    title: string;
    label: string;
    defaultValues?: CheckboxFilterValues;
    onCancel?: () => void;
    onSubmit: (values: CheckboxFilterValues) => void;
    onReset?: () => void;
    children: ReactNode | (() => ReactNode);
    count?: (values?: CheckboxFilterValues) => number;
    actions?: () => ReactNode;
    showResetButton?: boolean;
    icon?: JSX.Element;
} & ModalFilterOptions;

const FiltersModal = ({
    onSubmit,
    onReset,
    onCancel,
    defaultValues = {},
    title,
    label,
    children,
    count,
    showResetButton,
    icon,
    actions,
}: Props) => {
    const [t] = useTranslation();
    const form = useForm({ defaultValues });
    const titleId = useId();

    useEffect(() => {
        form.reset(defaultValues);
    }, [defaultValues]);

    const values = form.watch();

    const handleFormSubmit = useMemo(() => {
        return form.handleSubmit(values => {
            onSubmit(values);
        });
    }, [form.handleSubmit, onSubmit]);

    const showCounter = count && count?.(defaultValues) > 0;

    return (
        <>
            <ModalTrigger
                label={`${label} ${showCounter ? `(${count?.(defaultValues)})` : ""}`}
                onReset={onReset}
                showResetButton={showResetButton}
                icon={icon}
                count={count?.(defaultValues)}
            >
                {(close: () => void) => (
                    <Dialog aria-labelledby={titleId} aria-modal={true}>
                        <FormProvider {...form}>
                            <form
                                className="flex flex-col gap-8"
                                onSubmit={e => {
                                    e.preventDefault();
                                    handleFormSubmit().then(() => close());
                                }}
                            >
                                <Typography variant="label" className="mb-2" tag="h1" id={titleId}>
                                    {title}{" "}
                                    {count && isNumber(count?.(values)) ? (
                                        <Typography tag="span" className="text-grey-light">
                                            (
                                            {t("@sol.filters.FiltersModalHeader.applied", {
                                                count: count(values),
                                            })}
                                            )
                                        </Typography>
                                    ) : null}
                                </Typography>

                                {children}

                                <div className="flex flex-row justify-between">
                                    <Button
                                        variant="primary"
                                        className="!bg-white-base !text-purple-base"
                                        onPress={() => {
                                            close();
                                            onCancel?.();
                                        }}
                                    >
                                        {t("@sol.filters.FiltersModal.cancel")}
                                    </Button>
                                    <div className="flex items-center gap-10">
                                        {actions && actions()}
                                        <Button data-cy="apply-filter" type="submit" variant="primary">
                                            {t("@sol.filters.FiltersModal.submit")}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </Dialog>
                )}
            </ModalTrigger>
        </>
    );
};

export default styled(FiltersModal)`
    width: 100%;

    ${Body} {
        margin: ${({ theme }) => theme.spacing[5]} 0;
        max-height: calc(100vh - ${({ theme }) => theme.spacing[5]} * 2);
    }

    ${FiltersModalHeader} {
        margin-bottom: ${({ theme }) => theme.spacing[9]};
    }

    ${Actions} {
        margin-top: ${({ theme }) => theme.spacing[9]};
    }
`;
