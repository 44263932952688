import { HTMLAttributes, MutableRefObject, memo, forwardRef } from "react";
import { useObjectRef } from "react-aria";
import styled, { css } from "styled-components";

import Text from "../Text";
import { getVariant, TabVariant } from "./Tab";

type Props = HTMLAttributes<HTMLButtonElement> & {
    label?: string;
    variant?: TabVariant;
};

const Label = styled.span`
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TabButton = (
    { label, children, className, ...props }: Props,
    forwarderRef: MutableRefObject<HTMLButtonElement>,
) => {
    const ref = useObjectRef(forwarderRef);
    return (
        <button
            {...props}
            type="button"
            ref={ref}
            className={`${className} focus-visible:ring focus-visible:ring-inset focus-visible:ring-blue-focus focus-visible:ring-offset-2 focus-visible:ring-offset-white-base`}
            onFocus={evt => {
                const element = ref.current;
                const parentElement = element?.parentElement;
                evt.preventDefault();
                evt.stopPropagation();
                if (element && parentElement) {
                    const { offsetLeft } = element;
                    parentElement.scrollTo({ left: offsetLeft });
                }

                props.onFocus?.(evt);
            }}
        >
            <Text as="span" variant="label">
                {label && <Label>{label}</Label>}
                {children}
            </Text>
            <span></span>
        </button>
    );
};

export default styled(memo(forwardRef(TabButton)))<Props>`
    ${({ theme, variant = "default" }) => {
        const { textColor, textColorSelected } = getVariant(theme, variant);

        return css`
            flex: 1 1 auto;
            display: flex;

            outline: none;

            ${variant === "secondary" &&
            css`
                flex: 0;
                padding: ${theme.spacing[2]};
                margin-bottom: 1px;
                border: 0;
                background-color: ${theme.palette.white.base};
                border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

                :hover,
                :active {
                    --icon-color: ${textColorSelected};
                    --text-color: ${textColorSelected};
                    :not(:focus) {
                        border-color: ${theme.palette.purple.lighter2};
                    }
                }
            `};

            ${variant === "default" &&
            css`
                position: relative;

                height: 66px;

                padding: ${theme.spacing[4]} ${theme.spacing[4]} ${theme.spacing[6]};

                border: 2px solid ${theme.palette.grey.lightest};
                border-bottom-width: 0;
                border-radius: ${theme.shape.borderRadius.medium};
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                background-color: ${theme.palette.grey.lightest};

                :hover,
                :active {
                    background-color: ${theme.palette.purple.lighter2};
                    --icon-color: ${textColorSelected};
                    --text-color: ${textColorSelected};
                }
            `}

            --icon-color: ${textColor};
            --text-color: ${textColor};

            text-align: center;

            > ${Text} {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            cursor: pointer;
        `;
    }}
`;
