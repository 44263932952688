import { ComponentPropsWithoutRef, ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useRestrictedScope } from "@sol/access-control";
import { Feature, useCheckFeatureFlag } from "@sol/features";
import { Modal } from "@sol/modal";
import { Route, interpolate as interpolateRoute, useRouter } from "@sol/routing";
import {
    BriefStatus,
    IBriefResource,
    IGroupSpaceResource,
    ITopicResource,
    getResourceUuid,
    useDuplicateBriefMutation,
} from "@sol/sdk";
import { Container } from "@sol/uikit";
import { Menu, MenuItem, MenuTrigger } from "@sol/uikit/collections/Menu";

import ApiErrorAlert from "../../ApiErrorAlert";
import CoeditorsPicker from "../../shared/CoeditorsPicker";
import BriefDeletionDialog from "./BriefDeletionDialog";

const CoeditorsModal = styled(Container)`
    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    margin: ${({ theme }) => `${theme.spacing[5]} auto`};
`;

export enum BriefOptionsAction {
    EDIT_COEDITORS = "editCoeditors",
    DELETE = "delete",
    DUPLICATE = "duplicate",
}

type BriefOptions = {
    key: string;
    label: string;
    href?: string;
    action?: () => void;
};

type Props = {
    // TODO: improve SDK typing to handle brief status, topic, groupSpace
    brief: IBriefResource & {
        status?: BriefStatus;
        topic?: Pick<ITopicResource, "@type" | "@id">;
        groupSpace?: Pick<IGroupSpaceResource, "@type" | "@id">;
    };
    onAction?: (action: BriefOptionsAction) => void;
    button: ReactNode;
} & Pick<ComponentPropsWithoutRef<typeof Menu>, "variant">;

const BriefActionsMenu = ({ brief, button, variant, onAction }: Props) => {
    const [t] = useTranslation();
    const scope = useRestrictedScope();
    const checkFeatureFlag = useCheckFeatureFlag();
    const router = useRouter();

    const [popupType, setPopupType] = useState<BriefOptionsAction | null>(null);
    const {
        mutate: duplicateBrief,
        error: duplicateBriefError,
        reset: resetBriefMuation,
    } = useDuplicateBriefMutation({
        onSuccess({ data: brief }) {
            router.push({
                route: Route.BRIEFS_EDIT,
                query: {
                    id: getResourceUuid(brief),
                },
            });
        },
    });

    const handleAction = useCallback(
        (action: BriefOptionsAction) => {
            switch (action) {
                case BriefOptionsAction.EDIT_COEDITORS:
                case BriefOptionsAction.DELETE:
                    setPopupType(action);
                    break;
                case BriefOptionsAction.DUPLICATE:
                    duplicateBrief({ brief });
                    break;
            }
        },
        [onAction, duplicateBrief, brief],
    );

    const options = useMemo<BriefOptions[]>(() => {
        const finalOptions: BriefOptions[] = [];

        if (checkFeatureFlag(Feature.BRIEFS_EDIT, { scope: scope(brief) })) {
            finalOptions.push({
                key: "edit",
                label: t("component.shared.useBriefOptions.edit"),
                href: interpolateRoute(Route.BRIEFS_EDIT, { id: getResourceUuid(brief) }),
            });
        }

        if (checkFeatureFlag(Feature.BRIEFS_DELETE, { scope: scope(brief) })) {
            finalOptions.push({
                key: "delete",
                label: t("component.shared.useBriefOptions.delete"),
                action: () => handleAction(BriefOptionsAction.DELETE),
            });
        }

        if (checkFeatureFlag(Feature.BRIEFS_DUPLICATE, { scope: scope(brief) })) {
            finalOptions.push({
                key: "duplicate",
                label: t("component.shared.useBriefOptions.duplicate"),
                action: () => handleAction(BriefOptionsAction.DUPLICATE),
            });
        }

        if (checkFeatureFlag(Feature.BRIEFS_EDIT_COEDITORS, { scope: scope(brief) })) {
            finalOptions.push({
                key: "editCoeditors",
                label: t("component.shared.useBriefOptions.editCoeditors"),
                action: () => handleAction(BriefOptionsAction.EDIT_COEDITORS),
            });
        }

        // TODO: scope this feature to the classroom ?
        if (checkFeatureFlag(Feature.CLASSROOM_BRIEFS_MANAGE) && brief.status === BriefStatus.VALIDATED) {
            finalOptions.push(
                {
                    key: "assign",
                    label: t("component.shared.useBriefOptions.assign"),
                    href: interpolateRoute(Route.BRIEFS_ASSIGN, {
                        id: getResourceUuid(brief),
                    }),
                },
                {
                    key: "groupAssign",
                    label: t("component.shared.useBriefOptions.groupAssign"),
                    href: interpolateRoute(Route.BRIEFS_GROUP_ASSIGN, {
                        id: getResourceUuid(brief),
                    }),
                },
            );
        }

        if (checkFeatureFlag([Feature.TOPICS_WORK_SUBMIT, Feature.WORKSPACE_GROUP_SUBMIT])) {
            if (brief.topic) {
                const id = getResourceUuid(brief.topic);

                finalOptions.push({
                    key: "submitIndividualWork",
                    label: t("component.shared.useBriefOptions.submitIndividualWork"),
                    href: interpolateRoute(Route.TOPICS_DETAILS, {
                        id,
                    }),
                });
            }

            if (brief.groupSpace) {
                const id = getResourceUuid(brief.groupSpace);

                finalOptions.push({
                    key: "submitGroupWork",
                    label: t("component.shared.useBriefOptions.submitGroupWork"),
                    href: interpolateRoute(Route.WORKSPACE_GROUP_DETAILS, {
                        id,
                        slug: "submit",
                    }),
                });
            }
        }

        return finalOptions;
    }, [brief, checkFeatureFlag, t, scope, handleAction]);

    if (options.length === 0) {
        return null;
    }

    const closeModal = () => setPopupType(null);

    return (
        <>
            <MenuTrigger>
                {button}
                <Menu
                    variant={variant}
                    placement="bottom end"
                    onAction={key => options?.find(item => item.key === key)?.action?.()}
                    items={options}
                >
                    {({ key, label, href }) => (
                        <MenuItem id={key} href={href}>
                            {label}
                        </MenuItem>
                    )}
                </Menu>
            </MenuTrigger>
            <BriefDeletionDialog
                brief={brief}
                onClickConfirm={() => {
                    closeModal();
                    onAction?.(BriefOptionsAction.DELETE);
                }}
                open={popupType === BriefOptionsAction.DELETE}
                onClickCancel={closeModal}
            />
            <Modal isOpen={popupType === BriefOptionsAction.EDIT_COEDITORS}>
                <CoeditorsModal>
                    <CoeditorsPicker
                        briefId={getResourceUuid(brief)}
                        onCancel={closeModal}
                        onSubmit={() => {
                            closeModal();
                            onAction?.(BriefOptionsAction.EDIT_COEDITORS);
                        }}
                    />
                </CoeditorsModal>
            </Modal>
            <ApiErrorAlert error={duplicateBriefError} dissmiss={resetBriefMuation} />
        </>
    );
};

export default BriefActionsMenu;
