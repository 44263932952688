import React, { ReactNode } from "react";
import { Text } from "src/uikit";
import styled from "styled-components";

type Props = {
    className?: string;
    children: ReactNode;
};

const NoBriefYet = ({ className, children }: Props) => {
    return <div className={className}>{children}</div>;
};

export default styled(NoBriefYet)`
    max-width: 352px;
    height: 448px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: ${({ theme }) => theme.spacing[6]};
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};

    ${Text} {
        padding: ${({ theme }) => theme.spacing[4]};
        background-color: ${({ theme }) => theme.palette.grey.lightest};
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        text-align: center;
        margin-bottom: ${({ theme }) => theme.spacing[5]};
    }

    > div {
        display: flex;
        justify-content: center;
    }
`;
