import { useOverlayTriggerState } from "@react-stately/overlays";
import { AnimatePresence } from "framer-motion";
import { cloneElement, ReactElement } from "react";
import { useOverlayTrigger } from "react-aria";
import { useTranslation } from "react-i18next";

import { Cross } from "@sol/uikit/core/icons";
import { Button } from "@sol/uikit/molecules/Button";
import { IconButton } from "@sol/uikit/molecules/IconButton";

import { Modal } from "./shared/Modal";

type Props = {
    label: string;
    children: (close: () => void) => ReactElement;
    showResetButton?: boolean;
    onReset?: () => void;
    icon?: JSX.Element;
    count?: number;
};

export const ModalTrigger = ({
    label,
    children,
    onReset,
    icon,
    count = 0,
    showResetButton = false,
    ...props
}: Props) => {
    const [t] = useTranslation();
    const state = useOverlayTriggerState(props);
    const { triggerProps, overlayProps } = useOverlayTrigger({ type: "dialog" }, state);

    return (
        <>
            <div className="flex items-center gap-2">
                <Button variant="secondary" {...triggerProps}>
                    {label}
                    {icon && count <= 0 && icon}
                </Button>
                {showResetButton && onReset && count > 0 && (
                    <IconButton
                        label={t("@sol.filters.FiltersModal.delete")}
                        variant="secondary"
                        onPress={() => onReset()}
                        data-cy="reset-filters"
                        labelVisible={false}
                    >
                        <Cross stroke="purple-base" />
                    </IconButton>
                )}
            </div>
            <AnimatePresence>
                {state.isOpen && <Modal state={state}>{cloneElement(children(state.close), overlayProps)}</Modal>}
            </AnimatePresence>
        </>
    );
};
