import { LayoutGroup, motion } from "framer-motion";
import React, { ReactNode } from "react";
import { useId } from "react-aria";
import styled from "styled-components";

import { Route } from "@sol/routing";

import SROnly from "../SROnly";
import SegmentedControlsItem from "./SegmentedControlsItem";
import { Variant } from "./types";

type Option = {
    id: string | Route;
    icon?: ReactNode;
    label: ReactNode;
};

type Props = {
    className?: string;
    title: string;
    options: Option[];
    value?: string;
    onChange: (id: string | Route) => void;
    orientation?: "vertical" | "horizontal";
    variant?: Variant;
    itemDescribedBy?: string;
};

type ListProps = Pick<Props, "orientation">;

const List = styled.ul<ListProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: ${({ theme }) => theme.spacing[2]};
    margin: 0;

    & > li {
        flex: 1;
        display: flex;
        list-style: none;
    }
`;

const SegmentedControls = ({
    className,
    title,
    options,
    value,
    onChange,
    orientation,
    itemDescribedBy,
    ...rest
}: Props) => {
    const layoutId = useId();

    return (
        <nav className={className} {...rest}>
            <SROnly>{title}</SROnly>
            <LayoutGroup id={layoutId}>
                <List as={motion.ul}>
                    {options.map(({ id, icon, label }) => (
                        <li key={id}>
                            <SegmentedControlsItem
                                orientation={orientation}
                                icon={icon}
                                onClick={() => onChange(id)}
                                isSelected={id === value}
                                describedBy={itemDescribedBy}
                            >
                                {label}
                            </SegmentedControlsItem>
                        </li>
                    ))}
                </List>
            </LayoutGroup>
        </nav>
    );
};

export default styled(SegmentedControls)`
    background-color: ${({ theme, variant }) =>
        variant === "primary" ? theme.palette.white.base : theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    > div {
        flex: 1;
    }

    ${List} {
        ${({ orientation }) => orientation === "vertical" && "flex-direction: column;"}

        > li + li {
            ${({ orientation, theme }) => `margin-${orientation === "vertical" ? "top" : "left"}: ${theme.spacing[2]}`};
        }
    }
`;
