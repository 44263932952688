import clamp from "lodash/clamp";
import isUndefined from "lodash/isUndefined";

/**
 * Create a sliding window numbers array that contains
 * at most "length" numbers between "min" and "max"
 * generated at most evenly arround a pivot number
 * @param length the window length
 * @param pivot the pivot number
 * @param min the lower bound (included)
 * @param max the upper bound (included)
 * @returns the sliding window array
 */
export const slidingWindow = (length: number, pivot: number, min: number, max: number): number[] => {
    if (max - min < 0) {
        // There is no room for any number
        return [];
    }

    // We ensure pivot is between min and max
    const clampedPivot = clamp(pivot, min, max);

    // The window contains at least the pivot
    const window = [clampedPivot];

    // Constrain windowLength with min and max
    const maxWindowLength = max - min + 1;
    const windowLength = Math.min(maxWindowLength, length);

    const hasExpectedLength = (window: any) => window.length === windowLength;

    // Loop while the result as not the expected length
    while (!hasExpectedLength(window)) {
        const first = window[0];
        const last = window[window.length - 1];

        // Only add before if there is room
        if (first > min) {
            window.unshift(first - 1);
        }

        // Only add after if there is room
        if (!hasExpectedLength(window) && last < max) {
            window.push(last + 1);
        }
    }

    return window;
};

/**
 * Get the absolute distance between two numbers
 * return -1 if one of the parameter is undefined
 * @param a
 * @param b
 */
export const getDistance = (a?: number, b?: number): number => {
    if (isUndefined(a) || isUndefined(b)) {
        return -1;
    }

    return Math.abs(b - a);
};
