import React, { ReactNode } from "react";
import UserRow from "src/components/shared/UserRow";
import { BlockSkeleton } from "src/components/skeletons";
import { Text } from "src/uikit";
import styled from "styled-components";

import { IUserResource } from "@sol/sdk";

export const UserPickerListUsersList = styled.ul`
    position: relative;
    list-style-type: none;
    overflow: auto;
    padding: 0;
    margin: ${({ theme }) => theme.spacing[9]} 0 0 0;

    li + li {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

const NoUserMessage = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing[9]};
`;

type LoaderProps = {
    className?: string;
};

const Loader = styled(({ className }: LoaderProps) => {
    return (
        <div className={className}>
            <BlockSkeleton />
            <BlockSkeleton />
            <BlockSkeleton />
        </div>
    );
})`
    margin-top: ${({ theme }) => theme.spacing[9]};

    ${BlockSkeleton} {
        height: 80px;
        width: 100%;
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

        & + ${BlockSkeleton} {
            margin-top: ${({ theme }) => theme.spacing[5]};
        }
    }
`;

type Props = {
    title: string;
    subtitle?: string | ReactNode;
    footer?: ReactNode;
    noUserMessage: string;
    users?: IUserResource[];
    isLoading?: boolean;
    onUserRemove: (userEmail: string) => void;
    className?: string;
};

const UserPickerList = ({
    title,
    subtitle,
    footer,
    noUserMessage,
    users = [],
    isLoading = false,
    onUserRemove,
    className,
}: Props) => {
    let children;

    if (isLoading) {
        children = <Loader />;
    } else if (users.length < 1) {
        children = <NoUserMessage data-cy="no-user-message">{noUserMessage}</NoUserMessage>;
    } else {
        children = (
            <UserPickerListUsersList data-cy="user-picker-list">
                {users.map(user => (
                    <li key={user.email}>
                        <UserRow small user={user} actionType="remove" onClick={() => onUserRemove(user.email)} />
                    </li>
                ))}
            </UserPickerListUsersList>
        );
    }

    return (
        <div className={className}>
            <div>
                <Text as="h2" variant="h4">
                    {title}
                </Text>
                {subtitle}
                {children}
            </div>
            {footer && footer}
        </div>
    );
};

export default styled(UserPickerList)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
`;
