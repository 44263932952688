import first from "lodash/first";
import last from "lodash/last";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import Pager from "../IPager";
import PageButton from "./PageButton";
import { slidingWindow, getDistance } from "./utils";

const Li = styled.li`
    ${({ theme }) => {
        return css`
            ${theme.typography.subheading}
            color: ${theme.palette.purple.base};
        `;
    }}
    & + & {
        padding-left: ${({ theme }) => theme.spacing[3]};
    }
`;

type Props = {
    max?: number;
    variant?: "primary" | "secondary";
} & Pager;

const ExtendedPager = ({ total, current, onChange, max = 5, variant = "primary" }: Props) => {
    const [t] = useTranslation();

    const pagesWindow = useMemo(() => slidingWindow(max, current, 2, total - 1), [max, current, total]);

    const showLeftSeparator = getDistance(first(pagesWindow), 1) > 1;
    const showRightSeparator = getDistance(last(pagesWindow), total) > 1;
    const showLastPage = total > 1;

    const createPageButtonForPage = (page: number) => {
        const isCurrent = current === page;
        return (
            <PageButton
                aria-current={isCurrent}
                onClick={() => {
                    if (!isCurrent) {
                        onChange(page);
                    }
                }}
                aria-label={t("uikit.pagination.goToPage", { pageNumber: page })}
                variant={variant}
                data-cy="extended-pager-link-item"
            >
                {page}
            </PageButton>
        );
    };

    return (
        <>
            <Li>{createPageButtonForPage(1)}</Li>
            {showLeftSeparator && <Li aria-hidden="true">...</Li>}
            {total > 2 && pagesWindow.map(page => <Li key={page}>{createPageButtonForPage(page)}</Li>)}
            {showRightSeparator && <Li aria-hidden="true">...</Li>}
            {showLastPage && <Li>{createPageButtonForPage(total)}</Li>}
        </>
    );
};

export default ExtendedPager;
