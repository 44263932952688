import Link from "next/link";
import React from "react";
import { MenuTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import BriefCard from "src/components/briefs/shared/BriefCard";
import { Text } from "src/uikit";
import Pagination from "src/uikit/Pagination";
import ExtendedPager from "src/uikit/Pagination/ExtendedPager";
import styled from "styled-components";

import { Route } from "@sol/routing";
import { IBriefList } from "@sol/sdk";
import { BriefOrder, BriefOrderBy } from "@sol/sdk/briefs/useBriefs";
import { Grid, GridColumn } from "@sol/uikit";
import { Menu, MenuItem } from "@sol/uikit/collections/Menu";
import { Brief, Chevron } from "@sol/uikit/core/icons";
import { Button } from "@sol/uikit/molecules/Button";

import { BlockSkeleton } from "../../skeletons";
import NoBriefYet from "../NoBriefYet";
import { BriefOptionsAction } from "../shared/BriefActionsMenu";

const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[7]};
`;

const BriefsCount = styled(Text)`
    color: ${({ theme }) => theme.palette.grey.base};
`;

export type SortBrief = {
    orderBy: BriefOrderBy;
    order: BriefOrder;
    labelKey: string;
};

export const SORT_BRIEF_OPTIONS: SortBrief[] = [
    { orderBy: "createdAt", order: "desc", labelKey: "createdDate" },
    { orderBy: "updatedAt", order: "desc", labelKey: "updatedDate" },
    { orderBy: "title", order: "asc", labelKey: "alphaOrder" },
];

export const BRIEF_CARDS_PER_PAGE = 9;

type Props = {
    className?: string;
    allBriefs?: IBriefList;
    fetchBriefs: () => void;
    isLoading: boolean;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    sort: SortBrief;
    setSort: (option: SortBrief) => void;
};

const keyPrefix = "component.briefs.BriefListView";

const BriefsResults = ({
    className,
    allBriefs,
    isLoading,
    currentPage,
    setCurrentPage,
    fetchBriefs,
    sort,
    setSort,
}: Props) => {
    const [t] = useTranslation(undefined, { keyPrefix });
    const options = SORT_BRIEF_OPTIONS.map(option => ({
        key: option.labelKey,
        action: () => setSort(option),
        label: t(`sortBy.options.${option.labelKey}`),
    }));

    const results = allBriefs?.["hydra:member"];
    const count = allBriefs?.["hydra:totalItems"];
    const totalPages = count ? Math.ceil(count / BRIEF_CARDS_PER_PAGE) : 0;

    let values = (
        <NoBriefYet>
            <Text>{t("noBriefCallToActionAsTrainer")}</Text>
            <div>
                <Link href={Route.BRIEFS_CREATE} passHref>
                    <Button variant="primary">
                        {t("createBrief")}
                        <Brief fill="transparent-base" />
                    </Button>
                </Link>
            </div>
        </NoBriefYet>
    );

    if (isLoading) {
        values = <BlockSkeleton className="min-h-[450px]" />;
    }

    if (results && results.length > 0) {
        values = (
            <Grid>
                {results.map(brief => (
                    <GridColumn col={4} key={brief["@id"]}>
                        <BriefCard
                            brief={brief}
                            onAction={action => {
                                if (action === BriefOptionsAction.DELETE) {
                                    setCurrentPage(1);
                                    fetchBriefs();
                                }
                            }}
                        />
                    </GridColumn>
                ))}
            </Grid>
        );
    }

    return (
        <div className={className}>
            <Heading>
                <BriefsCount data-cy="briefs-matching">
                    {!isLoading && t("briefsFound", { count: count || 0 })}
                </BriefsCount>
                <MenuTrigger>
                    <Button
                        variant="primary"
                        className="!border-grey-lightest !bg-grey-lightest !text-sm !font-normal !text-black-base"
                    >
                        {t("sortBy.label", { option: t(`sortBy.options.${sort.labelKey}`) })}
                        <Chevron direction="down" fill="transparent" stroke="purple-base" />
                    </Button>
                    <Menu
                        variant="secondary"
                        onAction={key => options.find(item => item.key === key)?.action()}
                        items={options}
                    >
                        {({ key, label }) => <MenuItem id={key}>{label}</MenuItem>}
                    </Menu>
                </MenuTrigger>
            </Heading>

            {values}

            <Pagination
                current={currentPage}
                total={totalPages}
                onChange={setCurrentPage}
                pager={options => <ExtendedPager variant="secondary" {...options} />}
            />
        </div>
    );
};

export default styled(BriefsResults)`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing[7]};
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    ${Grid} {
        min-height: 450px; //height of a <HomeBriefCard />
    }
    ${Pagination} {
        margin-top: ${({ theme }) => theme.spacing[7]};
    }

    ${BlockSkeleton} {
        border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    }
`;
