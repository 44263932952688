import isUndefined from "lodash/isUndefined";
import { useEffect } from "react";

import { useAnimationFrameState } from "./useAnimationFrameState";

type State = number | undefined;

export const useScrollSpy = (
    element: HTMLElement | null,
    ids: string[],
    { offset = 0, initialState }: { offset?: number; initialState?: State },
) => {
    const [activeIndex, setActiveIndex] = useAnimationFrameState<State>(initialState);

    useEffect(() => {
        if (element) {
            const container = element;

            const scrollHandler = (event: Event) => {
                if (!event?.currentTarget) {
                    return;
                }

                const { scrollTop, offsetHeight: viewportHeight } = event.currentTarget as HTMLElement;

                let activeIndex: State = undefined;

                ids?.forEach((id, index) => {
                    const element = document.getElementById(id);
                    if (!element) {
                        return;
                    }
                    const { offsetTop } = element;

                    const top = offsetTop + offset;

                    if (top >= scrollTop && top <= scrollTop + viewportHeight) {
                        if (isUndefined(activeIndex)) {
                            activeIndex = index;
                        } else {
                            const activeElement = document.getElementById(ids[activeIndex]);

                            activeIndex = activeElement && activeElement.offsetTop + offset < top ? activeIndex : index;
                        }
                    }
                });

                // Here we keep the old value if we are unable to find a new active item
                setActiveIndex(previousIndex => activeIndex ?? previousIndex);
            };

            container.addEventListener("scroll", scrollHandler, {
                capture: true,
                passive: true,
            });

            return () => {
                container.removeEventListener("scroll", scrollHandler);
            };
        }
    }, [element, ids, offset]);

    return activeIndex;
};

export default useScrollSpy;
