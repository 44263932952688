import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import StickyBox from "react-sticky-box";
import SegmentedControls from "src/uikit/SegmentedControls";
import { useTheme } from "styled-components";

import { Feature, useCheckFeatureFlag } from "@sol/features";
import { Brief, EmptyWork, Users, Explore } from "@sol/icons";
import { Route } from "@sol/routing";
import { Grid, GridColumn, Container } from "@sol/uikit/layouts";

import useScroll from "../../../hooks/useScroll";
import MetaTitle from "../../MetaTitle";
import BriefsAssigned from "./BriefsAssigned";
import BriefsCollaborate from "./BriefsCollaborate";
import BriefsExplore from "./BriefsExplore";
import MyBriefs from "./MyBriefs";
import MyBriefsAssigned from "./MyBriefsAssigned";

export const useBriefsViews = () => {
    const checkFeatureFlag = useCheckFeatureFlag();
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListPage" });

    return useMemo(() => {
        const options = [];

        const getBaseOption = (prefix: string) => {
            return {
                title: t(`${prefix}.title`),
                label: t(`${prefix}.label`),
            };
        };

        if (checkFeatureFlag(Feature.BRIEFS_CREATE)) {
            options.push({
                ...getBaseOption("MyBriefs"),
                id: Route.MY_BRIEFS,
                icon: <Brief />,
                view: <MyBriefs />,
            });
        } else if (checkFeatureFlag([Feature.TOPICS_WORK_SUBMIT, Feature.WORKSPACE_GROUP_SUBMIT])) {
            options.push({
                ...getBaseOption("MyBriefsAssigned"),
                id: Route.MY_BRIEFS,
                icon: <EmptyWork />,
                view: <MyBriefsAssigned />,
            });
        }

        if (checkFeatureFlag(Feature.CLASSROOM_BRIEFS)) {
            options.push({
                ...getBaseOption("BriefsAssigned"),
                id: Route.BRIEFS_ASSIGNED,
                icon: <EmptyWork />,
                view: <BriefsAssigned />,
            });
        }

        if (checkFeatureFlag(Feature.BRIEFS_CREATE)) {
            options.push({
                ...getBaseOption("BriefsCollaborate"),
                id: Route.BRIEFS_COLLABORATE,
                icon: <Users />,
                view: <BriefsCollaborate />,
            });
        }

        if (checkFeatureFlag(Feature.BRIEFS_EXPLORE)) {
            options.push({
                ...getBaseOption("BriefsExplore"),
                id: Route.BRIEFS_EXPLORE,
                icon: <Explore />,
                view: <BriefsExplore />,
            });
        }

        return options;
    }, [checkFeatureFlag, t]);
};

type Props = {
    value: string;
    handleChange: (id: string) => void;
};

const BriefsListPage = ({ handleChange, value }: Props) => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListPage" });
    const theme = useTheme();
    const scroll = useScroll();

    const views = useBriefsViews();

    useEffect(() => {
        scroll?.scrollTo?.({ behavior: "smooth", top: 0 });
    }, [value]);

    const currentView = useMemo(() => {
        return views.find(view => view.id === value);
    }, [views, value]);

    return (
        <Container>
            <Grid>
                <GridColumn col={1}>
                    <StickyBox offsetTop={parseInt(theme.spacing[5]) + theme.navbarHeight}>
                        {/* DEBT: we should use links instead of buttons */}
                        <SegmentedControls
                            orientation="vertical"
                            variant="primary"
                            onChange={handleChange}
                            value={value}
                            title={t("menuTitle")}
                            options={views}
                        />
                    </StickyBox>
                </GridColumn>
                <GridColumn col={11}>
                    {currentView ? (
                        <>
                            <MetaTitle title={currentView.title} />
                            {currentView.view}
                        </>
                    ) : null}
                </GridColumn>
            </Grid>
        </Container>
    );
};

export default BriefsListPage;
