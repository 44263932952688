import React, { ComponentProps, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Merge } from "type-fest";

import { Loading } from "@sol/icons";
import { IUser, IUserResource } from "@sol/sdk";
import { Button } from "@sol/uikit/molecules/Button";
import { GhostButton } from "@sol/uikit/molecules/GhostButton";

import ApiErrorAlert from "../../ApiErrorAlert";
import UserPickerForm from "./UserPickerForm";
import UserPickerList, { UserPickerListUsersList } from "./UserPickerList";

type Props = Merge<
    {
        className?: string;
        title: string;
        subtitle?: ReactNode | string;
        onUserAdd: (user: IUser) => void;
        showEmailOnly?: boolean;
        emailCheckingApiError?: string;
        emailCheckingLoading?: boolean;
        users: IUserResource[];
        usersListTitle: string;
        noUserMessage: string;
        onUserRemove: (email: string) => void;
        isLoading: boolean;
        onClose: () => void;
        onSubmit: () => void;
        isSubmitting: boolean;
        disabledSubmit?: boolean;
        apiErrorsReset?: () => void;
        apiErrors?: any;
    },
    Omit<ComponentProps<"div">, "title" | "onSubmit">
>;

const UserPicker = ({
    className,
    title,
    subtitle,
    onUserAdd,
    showEmailOnly,
    emailCheckingApiError,
    emailCheckingLoading,
    users,
    usersListTitle,
    noUserMessage,
    onUserRemove,
    isLoading,
    onClose,
    onSubmit,
    isSubmitting,
    disabledSubmit,
    apiErrorsReset,
    apiErrors,
    ...divProps
}: Props) => {
    const [t] = useTranslation();
    const submitButtonRef = useRef(null);

    return (
        <div {...divProps} className={className}>
            <div className="grid grid-cols-12 divide-x divide-grey-lightest p-4">
                <div className="col-span-6 pr-12">
                    <UserPickerForm
                        title={title}
                        subtitle={subtitle}
                        onUserAdd={onUserAdd}
                        showEmailOnly={showEmailOnly}
                        apiErrorMessage={emailCheckingApiError}
                        isLoading={emailCheckingLoading}
                    />
                </div>
                <div className="col-span-6 pl-12">
                    <UserPickerList
                        users={users}
                        title={usersListTitle}
                        noUserMessage={noUserMessage}
                        onUserRemove={onUserRemove}
                        isLoading={isLoading}
                        footer={
                            <div className="flex items-center justify-end gap-4">
                                <GhostButton variant="secondary" data-cy="close-modal" onPress={onClose}>
                                    {t("component.TutorPicker.cancel")}
                                </GhostButton>
                                <Button
                                    forwardRef={submitButtonRef}
                                    data-cy="user-picker-submit"
                                    variant="success"
                                    onPress={onSubmit}
                                    disabled={disabledSubmit}
                                >
                                    {t("component.TutorPicker.submit")}
                                    {isSubmitting && <Loading />}
                                </Button>
                            </div>
                        }
                    />
                </div>
            </div>
            {apiErrors && apiErrorsReset && (
                <ApiErrorAlert error={apiErrors} dissmiss={apiErrorsReset} triggerElement={submitButtonRef} />
            )}
        </div>
    );
};

export default styled(UserPicker)`
    ${UserPickerList} {
        ${UserPickerListUsersList} {
            max-height: 440px;
        }
    }
`;
