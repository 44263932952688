import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

import { IBrief, useApi } from "@sol/sdk";
import { SimplePopup, PopupMessageType, PopupType, type SimplePopupProps } from "@sol/uikit/molecules/SimplePopup";

import { useAsyncCallback } from "../../../modules/utils";
import ApiErrorAlert from "../../ApiErrorAlert";

type BriefDeletionDialogProps = {
    brief: Pick<IBrief, "@id" | "title">;
    onClickConfirm: () => void;
} & Pick<SimplePopupProps, "open" | "onClickCancel">;

const BriefDeletionDialog: FC<BriefDeletionDialogProps> = ({ brief, onClickConfirm, ...rest }) => {
    const [t] = useTranslation();
    const api = useApi();
    const confirmButtonRef = useRef(null);

    // TODO : Use SDK method when ready
    const [deleteBrief, { loading, error }, reset] = useAsyncCallback(async () => {
        await api.request({
            method: "DELETE",
            url: brief["@id"],
        });
        onClickConfirm();
    }, [brief]);

    return (
        <>
            <SimplePopup
                label={t("component.BriefDeletionDialog.confirmDelete", {
                    brief: brief.title,
                })}
                isConfirmLoading={loading}
                onClickConfirm={deleteBrief}
                popupType={PopupType.CONFIRM}
                messageType={PopupMessageType.INFO}
                confirmButtonRef={confirmButtonRef}
                confirmText={t("component.BriefDeletionDialog.delete")}
                {...rest}
            />
            {/* TODO : revise the errors UI. Remove the double modal ? */}
            <ApiErrorAlert error={error} dissmiss={reset} triggerElement={confirmButtonRef} />
        </>
    );
};

export default BriefDeletionDialog;
