/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * Source taken from @react-spectrum/utils@3.11.3
 */

import { DOMRef, DOMRefValue, FocusableElement, FocusableRef, FocusableRefValue } from "@react-types/shared";
import { RefObject, useImperativeHandle, useMemo, useRef } from "react";

export function createDOMRef<T extends HTMLElement = HTMLElement>(ref: RefObject<T>): DOMRefValue<T> {
    return {
        // @ts-ignore
        UNSAFE_getDOMNode() {
            return ref.current;
        },
    };
}

export function createFocusableRef<T extends HTMLElement = HTMLElement>(
    domRef: RefObject<T>,
    focusableRef: RefObject<FocusableElement> = domRef,
): FocusableRefValue<T> {
    return {
        ...createDOMRef(domRef),
        focus() {
            if (focusableRef.current) {
                focusableRef.current.focus();
            }
        },
    };
}

export function useDOMRef<T extends HTMLElement = HTMLElement>(ref: DOMRef<T>): RefObject<T> {
    const domRef = useRef<T>(null);
    useImperativeHandle(ref, () => createDOMRef(domRef));
    return domRef;
}

export function useFocusableRef<T extends HTMLElement = HTMLElement>(
    ref: FocusableRef<T>,
    focusableRef?: RefObject<FocusableElement>,
): RefObject<T> {
    const domRef = useRef<T>(null);
    // @ts-ignore
    useImperativeHandle(ref, () => createFocusableRef(domRef, focusableRef));
    return domRef;
}

export function unwrapDOMRef<T extends HTMLElement>(ref: RefObject<DOMRefValue<T>>): RefObject<T> {
    return {
        get current() {
            return ref.current && ref.current.UNSAFE_getDOMNode();
        },
    };
}

export function useUnwrapDOMRef<T extends HTMLElement>(ref: RefObject<DOMRefValue<T>>): RefObject<T> {
    return useMemo(() => unwrapDOMRef(ref), [ref]);
}
