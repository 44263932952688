import { motion } from "framer-motion";
import isNull from "lodash/isNull";
import memo from "lodash/memoize";
import React, { FC, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useEffectOnce, useEventListener } from "usehooks-ts";

import NavigationTabButton from "./NavigationTabButton";
import { TabVariant } from "./Tab";
import TabButton from "./TabButton";

type Props = {
    className?: string;
    maximumVisibleTabs?: number;
    tabsScrollOffset?: number;
    variant?: TabVariant;
};

const AnimatedNavigationButton = motion(NavigationTabButton);

const navigationProps = memo((right: boolean) => ({
    initial: "hidden",
    variants: {
        visible: {
            opacity: 1,
            [right ? "marginRight" : "marginLeft"]: 0,
            [!right ? "marginRight" : "marginLeft"]: 2,
            display: "block",
        },
        hidden: {
            opacity: 0,
            [right ? "marginRight" : "marginLeft"]: -48,
            [!right ? "marginRight" : "marginLeft"]: 0,
            transitionEnd: { display: "none" },
        },
    },

    transition: { duration: 0.1, type: "tween" },
}));

const Tabs: FC<Props> = ({ children, className, variant }) => {
    const [[prevIndex, nextIndex], setNavigation] = useState([-1, -1]);

    const listRef = useRef<HTMLDivElement>(null);

    const updateNavigation = () => {
        if (listRef.current) {
            const root = listRef.current;

            const { left: rootLeft, right: rootRight } = root.getBoundingClientRect();

            let headElement = listRef.current.firstElementChild;
            let prevIndex: number | null = null;
            let nextIndex: number | null = null;
            let i = 0;

            while (headElement) {
                const { right, left } = headElement.getBoundingClientRect();

                const isVisible = (x: number) => Math.ceil(x - rootLeft) >= 0 && Math.floor(x - rootRight) <= 0;

                const leftVisible = isVisible(left);
                const rightVisible = isVisible(right);

                if (isNull(prevIndex) && rightVisible) {
                    prevIndex = leftVisible ? i - 1 : i;
                }

                if ((isNull(nextIndex) || nextIndex <= i) && leftVisible) {
                    nextIndex = rightVisible ? i + 1 : i;
                }

                headElement = headElement.nextElementSibling;
                i++;
            }

            setNavigation([isNull(prevIndex) ? -1 : prevIndex, isNull(nextIndex) || nextIndex >= i ? -1 : nextIndex]);
        }
    };

    useEventListener("resize", updateNavigation);
    useEventListener("scroll", updateNavigation, listRef);
    useEffectOnce(updateNavigation);

    const scrollToTab = (index: number) => {
        if (listRef.current) {
            let headElement = listRef.current.firstElementChild;
            let i = 0;

            while (headElement && i <= index) {
                if (i === index) {
                    headElement.scrollIntoView({ behavior: "smooth", inline: "start" });
                }

                headElement = headElement.nextElementSibling;
                i++;
            }
        }
    };

    return (
        <nav className={className}>
            <ListContainer ref={listRef} role="tablist">
                {children}
            </ListContainer>

            <AnimatedNavigationButton
                isPrev
                onClick={() => scrollToTab(prevIndex)}
                tabIndex={-1}
                animate={prevIndex > -1 ? "visible" : "hidden"}
                aria-hidden="true"
                variant={variant}
                {...navigationProps(false)}
            />

            <AnimatedNavigationButton
                tabIndex={-1}
                onClick={() => scrollToTab(nextIndex)}
                animate={nextIndex > -1 ? "visible" : "hidden"}
                variant={variant}
                aria-hidden="true"
                {...navigationProps(true)}
            />
        </nav>
    );
};

const ListContainer = styled.div`
    display: flex;
    position: relative;

    margin: 0;
    min-width: 0;
    max-width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    overflow-x: scroll;

    text-align: left;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    ${TabButton} {
        scroll-snap-align: start;
    }
`;

export default styled(Tabs)<Props>`
    ${({ theme, variant = "default" }) => {
        return css`
            display: flex;
            position: relative;

            ${NavigationTabButton}:first-of-type {
                order: 0;
            }

            ${NavigationTabButton}:last-of-type {
                order: 2;
            }

            ${ListContainer} {
                order: 1;
            }

            ${variant === "secondary" &&
            css`
                width: 100%;
                margin: 0 ${theme.spacing[5]};

                ${ListContainer} {
                    width: 100%;
                    gap: ${theme.spacing[6]};
                    padding: ${theme.spacing[3]} ${theme.spacing[5]} ${theme.spacing[3]} ${theme.spacing[2]};
                    overflow: auto;

                    &::after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        background: ${theme.palette.grey.lightest};
                    }
                }
            `}

            ${variant === "default" &&
            css`
                ${ListContainer} {
                    overflow-x: auto;

                    border-radius: ${theme.shape.borderRadius.medium} ${theme.shape.borderRadius.medium} 0 0;

                    gap: ${theme.spacing[1]};
                    padding: 0;
                }
                &::after {
                    z-index: 1;
                    position: absolute;
                    content: "";
                    height: 16px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: ${theme.palette.white.base};
                    border: 2px solid ${theme.palette.purple.base};
                    border-bottom: 0;
                    border-radius: ${theme.shape.borderRadius.medium} ${theme.shape.borderRadius.medium} 0 0;
                }
            `}
        `;
    }};
`;
