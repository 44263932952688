import { motion } from "framer-motion";
import { ReactNode, useRef } from "react";
import { useModalOverlay, Overlay } from "react-aria";
import { OverlayTriggerState } from "react-stately";

type Props = {
    state: OverlayTriggerState;
    children: ReactNode;
};

export const Modal = ({ state, children, ...props }: Props) => {
    const ref = useRef(null);
    const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

    return (
        <Overlay>
            <>
                <motion.div
                    className="fixed inset-0 z-[9999] flex items-center justify-center bg-[rgba(0,0,0,0.2)]"
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    animate={{ opacity: 1, backdropFilter: "blur(16px)" }}
                    transition={{ duration: 0.2, ease: "easeIn" }}
                    {...(underlayProps as any)}
                >
                    <motion.div
                        initial={{ y: 20 }}
                        exit={{ y: 20 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.2, ease: "easeIn" }}
                        {...(modalProps as any)}
                        ref={ref}
                        className="h-fit max-h-[80vh] w-full max-w-[1256px] overflow-auto rounded-lg border border-white-base bg-white-base p-px"
                    >
                        {children}
                    </motion.div>
                </motion.div>
            </>
        </Overlay>
    );
};
