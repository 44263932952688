import isNumber from "lodash/isNumber";
import { ComponentPropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Text from "../../uikit/Text";

type Props = ComponentPropsWithoutRef<typeof Text> & {
    title: string;
    count?: number;
};

const FiltersModalHeader = ({ title, count, ...props }: Props) => {
    const [t] = useTranslation();
    return (
        <Text variant="h4" {...props}>
            {title}{" "}
            {isNumber(count) ? (
                <Text as="span" variant="p">
                    ({t("@sol.filters.FiltersModalHeader.applied", { count })})
                </Text>
            ) : null}
        </Text>
    );
};

export default styled(FiltersModalHeader)`
    > ${Text} {
        --text-color: ${({ theme }) => theme.palette.grey.light};
    }
`;
