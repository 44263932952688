import React from "react";
import { Placement } from "react-aria";
import { Menu as ReactAriaMenu, MenuProps as ReactAriaMenuProps } from "react-aria-components";
import { Merge } from "type-fest";

import { Popover } from "@sol/uikit/overlays/Popover";

type MenuItemRenderer<T extends object = object> = (
    item: T,
) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;

export type MenuProps<T extends object = object> = Merge<
    ReactAriaMenuProps<T>,
    {
        variant?: "primary" | "secondary" | "light";
        placement?: Placement;
        /**
         * A render function which returns a <MenuItem />
         */
        children: MenuItemRenderer<T>;
        /**
         * Constraint items to
         */
        items?: T[];
    }
>;

export const Menu = <T extends object = object>({ variant, placement, children, ...props }: MenuProps<T>) => {
    return (
        <Popover variant={variant} placement={placement} offset={12}>
            <ReactAriaMenu {...props} className="flex flex-col outline-none">
                {(item: T) => {
                    const itemVariant = variant === "light" ? "secondary" : variant;

                    // Pass MenuItemVariant to custom rendered child
                    const child = children(item);
                    return React.cloneElement(child, {
                        variant: itemVariant,
                        ...child.props,
                    });
                }}
            </ReactAriaMenu>
        </Popover>
    );
};
