/* eslint-disable react/display-name */
import React, { InputHTMLAttributes, forwardRef, RefObject } from "react";
import styled from "styled-components";

import { Check } from "@sol/icons";

export enum CheckboxColor {
    PURPLE = "purple",
    GREEN = "green",
}

type Color = { color: CheckboxColor };
type BoxProps = Pick<Props, "checked"> & Color;

const DEFAULT_COLOR = CheckboxColor.PURPLE;

const CheckIcon = styled(Check)`
    --icon-color: ${({ theme }) => theme.palette.white.base};

    height: 18px;
`;

export const Box = styled.span<BoxProps>`
    margin: 3px;
    position: relative;
    border: ${({ checked, color, theme }) =>
        `2px solid ${checked ? theme.palette[color].base : theme.palette.grey.light}`};
    background-color: ${({ checked, color, theme }) => theme.palette[checked ? color : "white"].base};
    box-sizing: border-box;
    border-radius: 3px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    padding: 0;
    cursor: pointer;
    outline: none;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CheckboxInput = styled.input<Color>`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:focus ~ ${Box} {
        outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
        outline-offset: 2px;
    }
`;

type Props = InputHTMLAttributes<HTMLInputElement> & {
    className?: string;
    color?: CheckboxColor;
};

const Checkbox = ({ className, color = DEFAULT_COLOR, ...inputProps }: Props, ref: RefObject<HTMLInputElement>) => {
    const { checked } = inputProps;

    return (
        <span className={className}>
            <CheckboxInput color={color} type="checkbox" ref={ref} {...inputProps} />
            <Box color={color} checked={checked}>
                {checked && <CheckIcon />}
            </Box>
        </span>
    );
};

export default styled(forwardRef(Checkbox))`
    display: inline-flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.white.base};
    outline: none;
    border: 0;
    transition: all 0.2s;
    justify-content: center;
    text-align: left;

    &:hover,
    &:focus {
        ${Box} {
            border: ${({ color, theme }) => `2px solid ${theme.palette[color || DEFAULT_COLOR].base}`};
        }

        &:active {
            ${Box} {
                border: ${({ color, theme }) => `2px solid ${theme.palette[color || DEFAULT_COLOR].base}`};
            }
        }
    }
`;
