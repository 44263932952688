import { ComponentPropsWithoutRef, forwardRef, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Chevron } from "@sol/icons";

import SROnly from "../SROnly";
import TabButton from "./TabButton";

type Props = {
    isPrev?: boolean;
} & ComponentPropsWithoutRef<typeof TabButton>;

const NavigationTabButton = ({ isPrev, children, ...buttonProps }: Props, ref: MutableRefObject<HTMLButtonElement>) => {
    const [t] = useTranslation();

    return (
        <TabButton {...buttonProps} data-cy={isPrev ? "previous" : "next"} ref={ref}>
            <SROnly>{isPrev ? t("uikit.tabs.displayPrevTabs") : t("uikit.tabs.displayNextTabs")}</SROnly>
            <Chevron direction={isPrev ? "left" : "right"} />
        </TabButton>
    );
};

export default styled(forwardRef(NavigationTabButton))<Props>`
    flex: none;

    ${({ theme, variant = "default" }) => css`
        ${variant === "secondary" &&
        css`
            padding: 0;
            background-color: transparent;
            border: 2px solid transparent;
            border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
            margin-bottom: ${theme.spacing[1]};
        `}

        --icon-color: ${theme.palette.purple.base};
        :hover,
        :active {
            background-color: ${theme.palette.purple.base};
            --text-color: ${theme.palette.white.base};
            --icon-color: ${theme.palette.white.base};

            :not(:focus) {
                border-color: ${theme.palette.purple.base};
            }
        }
    `}
`;
