import React, { FC, InputHTMLAttributes, DetailedHTMLProps } from "react";
import styled, { css } from "styled-components";

import Text from "../Text";

type RadioColor = "green" | "blue" | "purple";

const Radio: FC<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = props => {
    const { className, children, ...rest } = props;

    return (
        <Text as="label" variant="subheading" className={className}>
            <input type="radio" {...rest} />
            <span className="checkmark" />
            <span className="label">{children}</span>
        </Text>
    );
};

type StyledRadioProps = { color?: RadioColor };

export default styled(Radio)<StyledRadioProps>`
    ${({ color, theme, checked, children }) => {
        const radioColor = color || "green";

        return css`
            overflow: hidden;
            display: flex;
            align-items: center;

            position: relative;

            cursor: pointer;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .label {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 3px;
                line-height: 24px;
            }

            color: ${checked ? theme.palette.black.base : theme.palette.grey.base};

            /* Hide the browser's default radio button */
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            /* Create a custom radio button */
            .checkmark {
                display: inline-block;
                position: relative;
                height: 18px;
                width: 18px;

                margin: 3px;
                margin-right: ${children ? theme.spacing[3] : 3}px;

                border: 2px solid ${theme.palette.black.base};
                border-radius: 50%;
            }

            /* Create a custom checkmark */
            .checkmark:after {
                content: "";
                position: absolute;

                top: ${({ theme }) => theme.spacing[1]};
                left: ${({ theme }) => theme.spacing[1]};

                transform: scale(0);
                transition: transform 0.2s ease-in-out;

                width: calc(100% - 4px);
                height: calc(100% - 4px);
                background: ${theme.palette[radioColor].base};
                border-radius: 50%;
            }

            &:hover input ~ .checkmark {
                border: 2px solid ${theme.palette[radioColor].base};
            }

            input:active ~ .checkmark {
                border: 2px solid ${theme.palette[radioColor].base};
                box-shadow: 0 0 0 2px ${theme.palette[radioColor].base};
            }

            input:focus ~ .checkmark {
                outline: 3px solid ${({ theme }) => theme.palette.blue.focus};
            }

            input:checked ~ .checkmark:after {
                transform: scale(1);
            }
        `;
    }}
`;
