import { FC, memo, ComponentPropsWithoutRef } from "react";
import styled, { css } from "styled-components";

import { Theme } from "../theme";
import TabButton from "./TabButton";

export type TabVariant = "default" | "primary" | "secondary";

type Props = ComponentPropsWithoutRef<typeof TabButton> & {
    tabId: number;
    isSelected?: boolean;
    variant?: TabVariant;
};

export const getVariant = (theme: Theme, variant: TabVariant) => {
    switch (variant) {
        case "primary":
            return {
                textColor: theme.palette.grey.base,
                textColorSelected: theme.palette.purple.base,
            };
        case "secondary":
            return {
                textColor: theme.palette.grey.base,
                textColorSelected: theme.palette.purple.base,
            };
        default:
            return {
                textColor: theme.palette.black.base,
                textColorSelected: theme.palette.purple.base,
            };
    }
};

const Tab: FC<Props> = ({ tabId, isSelected, variant = "default", ...props }) => (
    <TabButton
        {...props}
        id={`tab-${tabId}`}
        aria-controls={`tabpanel-${tabId}`}
        role="tab"
        aria-selected={isSelected}
        variant={variant}
    />
);

export default styled(memo(Tab))<Props>`
    ${({ theme, isSelected, variant = "default" }) => {
        const { textColor, textColorSelected } = getVariant(theme, variant);
        const tabTextColor = isSelected ? textColorSelected : textColor;

        return css`
            position: relative;
            min-width: 200px;
            list-style: none;

            outline: none;

            --icon-color: ${tabTextColor};
            --text-color: ${tabTextColor};

            ${isSelected &&
            variant === "default" &&
            css`
                background-color: ${theme.palette.white.base};
                border-color: ${theme.palette.purple.base};
                :first-child,
                :last-child {
                    & > span:last-child {
                        position: absolute;
                        left: 50%;
                        height: 0.984rem;
                        z-index: 2;

                        ::before {
                            content: "";
                            position: absolute;
                            background: ${theme.palette.purple.base};
                        }
                    }

                    ::before {
                        transform: none;
                        position: absolute;
                        background: ${theme.palette.white.base};
                        bottom: 6px;
                        height: 20px;
                    }
                }
                :first-child {
                    & > span:last-child {
                        transform: translateX(-50%) rotateX(20deg);
                        width: calc(100% + 26px);
                        --mask: radial-gradient(
                            13px at 100% 0,
                            #0000 calc(98% - 2px),
                            #000 calc(100% - 2px) 98%,
                            #0000
                        );
                        bottom: 11px;

                        ::before {
                            mask: var(--mask);
                        }
                    }

                    ::before {
                        width: calc(100% + 10px);
                        left: 0;
                        right: -13px;
                        mask: radial-gradient(12px at 101% 0, #0000 98%, #000);
                    }
                }

                :last-child {
                    & > span:last-child {
                        transform: translateX(-50%) rotateX(23deg);
                        width: calc(100% + 24px);
                        --mask: radial-gradient(12px at 0 0, #0000 calc(98% - 2px), #000 calc(100% - 2px) 98%, #0000);
                        bottom: 10px;

                        ::before {
                            mask: var(--mask);
                        }
                    }

                    ::before {
                        width: calc(100% + 12px);
                        z-index: ${theme.zIndex.element + 1};
                        content: "";
                        left: -12px;
                        right: 0;
                        mask: radial-gradient(12px at 0% 0, #0000 98%, #000);
                    }
                }

                & > span:last-child {
                    transform: translateX(-50%) rotateX(30deg);
                    left: 50%;
                    position: absolute;
                    width: calc(100% + 27px);
                    height: 0.984rem;
                    --mask: radial-gradient(13px at 20px 0, #0000 calc(100% - 2px), #000 calc(100% - 2px) 101%, #0000) -20px;
                    bottom: 11px;
                    z-index: 2;

                    ::before {
                        content: "";
                        position: absolute;
                        inset: 0;
                        background: ${theme.palette.purple.base}; /* your background */
                        -webkit-mask: var(--mask);
                        mask: var(--mask);
                    }
                }

                ::before {
                    z-index: ${theme.zIndex.element + 1};
                    transform: translateX(-50%);
                    left: 50%;
                    content: "";
                    height: 16px;
                    right: -12px;
                    bottom: 10px;
                    background: ${theme.palette.white.base};
                    position: absolute;
                    mask: radial-gradient(12px at 20px 0, #0000 98%, #000) -20px;
                    width: calc(100% + 24px);
                }
            `}

            ${isSelected &&
            variant === "secondary" &&
            css`
                background-color: ${theme.palette.white.base};

                ::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    background: ${({ theme }) => theme.palette.purple.base};
                    width: 100%;
                    bottom: calc(0px - ${({ theme }) => theme.spacing[3]});
                    left: 0;
                    z-index: 2;
                }

                :focus::after {
                    left: 2px;
                    right: 2px;
                }
            `}
        `;
    }}
`;
