import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { getUserDisplayName, IUserResource } from "@sol/sdk";

import UserAvatar from "./UserAvatar";

type Props = {
    className?: string;
    user: IUserResource;
    message: string;
};

const WelcomeUser = ({ className, user, message }: Props) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <UserAvatar user={user} />
            <div>
                <Text as="span" preserve>
                    {t("page.home.welcome.greeting")}{" "}
                    <Text variant="label" as="span">
                        {getUserDisplayName(user) + ","}{" "}
                    </Text>
                    {message}
                </Text>
            </div>
        </div>
    );
};

export default styled(WelcomeUser)`
    display: flex;
    align-items: center;

    ${UserAvatar} {
        margin-right: ${({ theme }) => theme.spacing[3]};
        min-width: 48px;
    }
`;
