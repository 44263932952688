import { HTMLAttributes } from "react";
import styled from "styled-components";

type Props = {
    className?: string;
    tabId: number;
    isBordered?: boolean;
    tabIndex?: number;
} & HTMLAttributes<HTMLDivElement>;

const TabPanel = styled.div.attrs(({ tabId, tabIndex, isBordered = true, ...props }: Props) => ({
    role: "tabpanel",
    "aria-labelledby": `tab-${tabId}`,
    id: `tabpanel-${tabId}`,
    tabIndex: tabIndex ?? 0,
    isBordered: isBordered,
    ...props,
}))<Props>`
    background-color: ${({ theme }) => theme.palette.white.base};
    outline: none;

    border-radius: ${({ theme }) => {
        const radius = theme.shape.borderRadius.medium;

        return `0 0 ${radius} ${radius}`;
    }};

    border: ${({ isBordered = true, theme }) => (isBordered ? `2px solid ${theme.palette.purple.base}` : 0)};
    border-top: 0;
`;

export default TabPanel;
