import React, { useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ISkillItem, useFrameworks, useSkills } from "@sol/sdk";
import { Grid, GridColumn } from "@sol/uikit";

import { Tab, TabPanel, Tabs } from "../../../../uikit/Tabs";
import { TabVariant } from "../../../../uikit/Tabs/Tab";
import SkillsPickerSkill from "./SkillsPickerSkill";

type Props = {
    className?: string;
    value: string[];
    onChange: (value: string[]) => void;
    levels?: boolean;
    tabsVariant?: TabVariant;
    tabButtonVariant?: TabVariant;
    whiteBorders?: boolean;
    classroom?: string;
};

const isIDFromSkill = (id: string, skill: ISkillItem) => {
    const {
        ["@id"]: skillId,
        skillLevel1: { "@id": skillLevel1 },
        skillLevel2: { "@id": skillLevel2 },
        skillLevel3: { "@id": skillLevel3 },
    } = skill;

    if (skillId === id) {
        return true;
    }

    return [skillLevel1, skillLevel2, skillLevel3].includes(id);
};

const SkillsPicker = ({ value, onChange, levels, tabsVariant, tabButtonVariant, classroom, ...props }: Props) => {
    const tabPanelRef = useRef<HTMLDivElement | null>(null);
    const { data: frameworksData } = useFrameworks({ filters: { classrooms: classroom } });
    const frameworks = frameworksData?.["hydra:member"];

    const [selectedTabIndex, setselectedTabIndex] = useState(0);
    const selectedFramework = frameworks?.[selectedTabIndex];

    const { data: skillsData } = useSkills({
        filters: {
            frameworks: selectedFramework,
        },
        enabled: !!selectedFramework,
    });

    const handleTabSelection = useCallback(
        (selectedTabIndex: number) => {
            setselectedTabIndex(selectedTabIndex);
            tabPanelRef?.current?.focus();
        },
        [setselectedTabIndex],
    );

    return (
        <div {...props}>
            <Tabs variant={tabsVariant}>
                {frameworks?.map((framework, index) => (
                    <Tab
                        key={framework["@id"]}
                        label={framework.title}
                        isSelected={index === selectedTabIndex}
                        tabId={index}
                        onClick={() => handleTabSelection(index)}
                        variant={tabButtonVariant}
                    />
                ))}
            </Tabs>
            <TabPanel tabId={selectedTabIndex} ref={tabPanelRef} tabIndex={-1} className="focus-classes ring-inset">
                <Grid>
                    {skillsData?.["hydra:member"].map(skill => {
                        const skillIndex = value.findIndex(id => isIDFromSkill(id, skill));
                        return (
                            <GridColumn key={skill["@id"]} col={6}>
                                <SkillsPickerSkill
                                    skill={skill}
                                    levels={levels}
                                    value={skillIndex === -1 ? null : value[skillIndex]}
                                    onChange={(skillOrLevelId: string | null) => {
                                        if (!skillOrLevelId) {
                                            // remove skill or level from value
                                            onChange(value.filter((_, i) => i !== skillIndex));
                                        } else if (skillIndex > -1) {
                                            // replace skill or level
                                            onChange(value.map((id, i) => (i === skillIndex ? skillOrLevelId : id)));
                                        } else {
                                            // add skill or level
                                            onChange([...value, skillOrLevelId]);
                                        }
                                    }}
                                />
                            </GridColumn>
                        );
                    })}
                </Grid>
            </TabPanel>
        </div>
    );
};

export default styled(SkillsPicker)`
    display: flex;
    flex-direction: column;

    ${TabPanel} {
        flex: 1;
        overflow: auto;

        padding: ${({ theme }) => theme.spacing[5]};
    }

    ${({ theme, whiteBorders }) =>
        whiteBorders &&
        css`
            ${Tab}[aria-selected="false"] {
                border-color: ${theme.palette.white.base};
            }
        `}
`;
