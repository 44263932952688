import { cva, type VariantProps } from "class-variance-authority";

export type IconButtonVariantProps = VariantProps<typeof iconButtonVariants>;

const baseClasses = [
    "flex",
    "items-center",
    "justify-center",
    "gap-3",
    "rounded-full",
    "group",
    "transition-all",
    "duration-200",
    "ease-in-out",
    "focus:outline-0",
];
export const iconButtonVariants = cva(baseClasses, {
    variants: {
        position: {
            left: ["flex-row"],
            right: ["flex-row-reverse"],
        },
    },
    defaultVariants: {
        position: "left",
    },
});

export const iconClassNames = {
    primary: {
        className: [
            "bg-transparent-base",
            "group-enabled:hover:fill-white-base",
            "group-enabled:hover:stroke-white-base",
            "group-enabled:hover:border-purple-base",
            "group-enabled:hover:bg-purple-base",
            "group-enabled:hover:bg-purple-base",
            "group-active:fill-white-base",
            "group-active:border-purple-base",
            "group-active:bg-purple-base",
            "group-active:bg-purple-base",
            "group-focus:ring-blue-focus",
            "group-focus:ring-offset-2",
            "group-focus:ring-offset-white-base",
            "group-focus:ring",
            "group-disabled:fill-grey-base",
            "group-disabled:stroke-grey-base",
        ],
    },
    secondary: {
        className: [
            "bg-transparent-base",
            "group-hover:bg-purple-base",
            "group-enabled:hover:fill-white-base",
            "group-enabled:hover:stroke-white-base",
            "group-enabled:hover:border-purple-base",
            "group-enabled:hover:bg-purple-base",
            "group-enabled:hover:bg-purple-base",
            "group-active:fill-white-base",
            "group-active:border-purple-base",
            "group-active:bg-purple-base",
            "group-active:bg-purple-base",
            "group-focus:ring-blue-focus",
            "group-focus:ring-offset-2",
            "group-focus:ring-offset-white-base",
            "group-focus:ring",
            "group-disabled:fill-grey-base",
            "group-disabled:stroke-grey-base",
        ],
    },
};
