import { ReactNode, useRef } from "react";
import { AriaDialogProps, useDialog } from "react-aria";

import { Typography } from "@sol/uikit/core/Typography";

type Props = {
    title?: string;
    children: ReactNode;
} & AriaDialogProps;

export const Dialog = ({ title, children, ...props }: Props) => {
    const ref = useRef(null);
    const { dialogProps, titleProps } = useDialog(props, ref);

    return (
        <div {...dialogProps} aria-modal="true" ref={ref} className="p-8">
            <div role="document">
                {title && (
                    <Typography tag="h3" variant="label" {...titleProps} className="mb-6">
                        {title}
                    </Typography>
                )}
                {children}
            </div>
        </div>
    );
};
