import { cva } from "class-variance-authority";
import { ComponentPropsWithoutRef } from "react";
import { Popover as ReactAriaPopover } from "react-aria-components";

const popoverVariants = cva(
    [
        "flex",
        "min-w-[279px]",
        "max-w-[600px]",
        "flex-col",
        "rounded-lg",
        "shadow-primary",
        "entering:animate-in",
        "entering:fade-in",
        "entering:zoom-in-95",
        "exiting:animate-out",
        "exiting:fade-out",
        "exiting:zoom-out-95",
        "fill-mode-forwards",
    ],
    {
        variants: {
            variant: {
                primary: "bg-purple-base",
                secondary: "bg-grey-lightest",
                light: "bg-white-base",
            },
        },
        defaultVariants: {
            variant: "primary",
        },
    },
);

type Props = {
    /**
     * The color/style scheme variant to use
     * @default primary
     */
    variant?: "primary" | "secondary" | "light";
} & ComponentPropsWithoutRef<typeof ReactAriaPopover>;

export const Popover = ({ className, variant, ...props }: Props) => {
    return <ReactAriaPopover className={popoverVariants({ variant, className })} {...props} />;
};
