import { Dispatch, SetStateAction, useCallback, useRef, useState, useEffect } from "react";

export const useAnimationFrameState = <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] => {
    const frame = useRef(0);
    const [state, setState] = useState(initialState);

    const setRafState = useCallback((value: S | ((prevState: S) => S)) => {
        cancelAnimationFrame(frame.current);

        frame.current = requestAnimationFrame(() => {
            setState(value);
        });
    }, []);

    useEffect(() => {
        return () => {
            cancelAnimationFrame(frame.current);
        };
    }, []);

    return [state, setRafState];
};

export default useAnimationFrameState;
