import { MotionValue } from "framer-motion";
import { useEffect, useState } from "react";

function ObserveMotionValue<V>({
    value,
    children,
}: {
    value: MotionValue<V>;
    children: (value: V) => JSX.Element | null;
}) {
    const [state, setState] = useState(value.get());
    useEffect(() => {
        const handleChange = () => setState(value.get());

        const unsubscribe = value.onChange(handleChange);

        return () => unsubscribe();
    }, [value]);

    return children(state);
}

export default ObserveMotionValue;
