import { ComponentPropsWithoutRef } from "react";
import { BlockSkeleton } from "src/components/skeletons";
import styled from "styled-components";
import { Merge } from "type-fest";

import { useCategories } from "@sol/sdk";

import TagsPicker from ".";

const Skeleton = styled(BlockSkeleton)`
    height: 234px;
    width: 100%;
`;

type Props = Merge<
    ComponentPropsWithoutRef<typeof TagsPicker>,
    {
        value: string[];
        onChange: (value: string[]) => void;
    }
>;

const TagIdsPicker = ({ onChange, value }: Props) => {
    const { data: categoriesData, isLoading } = useCategories({});
    const categories = categoriesData?.["hydra:member"] || [];

    const tags = categories.reduce((acc, current) => [...acc, ...current.tags], []);

    const filteredTags = tags.reduce((acc, current) => {
        const isTagExist = acc.find(tag => tag["@id"] === current["@id"]);
        if (!isTagExist) {
            return [...acc, current];
        }
        return acc;
    }, []);

    const selectedTags = filteredTags.filter(tag => value.includes(tag["@id"]));

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <TagsPicker
            value={selectedTags}
            onChange={tags => onChange(tags.map(tag => tag["@id"]))}
            categories={categories}
        />
    );
};

export default TagIdsPicker;
