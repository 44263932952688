import React, { ButtonHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Cross, PlusSquared } from "@sol/icons";

import { Text } from "../../../../uikit";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & { pressed?: boolean };

const TagButton = ({ children, pressed, ...props }: Props) => {
    const [t] = useTranslation();

    const Icon = pressed ? Cross : PlusSquared;
    return (
        <button type="button" aria-pressed={!!pressed} data-cy="tag" {...props}>
            <Text as="span" variant="subheading">
                {children}
            </Text>

            <Icon size={12} />
            <span className="sr-only">{t("component.TagButton.addTag")}</span>
        </button>
    );
};

export default styled(TagButton)`
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    border: 1px solid;
    border-color: ${({ theme, pressed }) => (pressed ? theme.palette.purple.base : theme.palette.grey.lightest)};

    padding: calc(${({ theme }) => theme.spacing[2]} - 1px) calc(${({ theme }) => theme.spacing[4]} - 1px);

    white-space: nowrap;
    overflow: hidden;

    --text-color: ${({ theme, pressed }) => (pressed ? theme.palette.purple.base : theme.palette.grey.base)};
    --icon-color: ${({ theme }) => theme.palette.purple.base};
    background-color: ${({ theme }) => theme.palette.white.base};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[3]};

    cursor: pointer;

    :hover {
        border-color: ${({ theme }) => theme.palette.purple.base};
    }
`;
