import { useTranslation } from "react-i18next";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { AssignationType, BriefStatus } from "@sol/sdk";

import BriefsView from "../BriefListView";
import { BriefsViewFilter, useFilters } from "../BriefListView/filters";

const MyBriefsAssigned = () => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.briefs.BriefListPage.MyBriefsAssigned" });
    const { activeClassroom } = useActiveClassroom();
    const { user } = useAuthenticatedUser();

    const filters = useFilters(
        [{ viewFilter: BriefsViewFilter.ASSIGNATION_TYPE, backlist: [AssignationType.NONE], pinned: true }],
        [],
    );

    return (
        <BriefsView
            title={t("title")}
            welcomeMessage={t("welcomeMessage", {
                promo: activeClassroom?.title,
            })}
            filters={filters}
            queryFilters={queryFilters => ({
                ...queryFilters,
                status: BriefStatus.VALIDATED,
                assignedClassroom: activeClassroom,
                assignedLearner: user,
                activeClassroom,
                assignationType:
                    (queryFilters?.assignationType?.length || 0) > 0
                        ? queryFilters?.assignationType
                        : [AssignationType.GROUP, AssignationType.INDIVIDUAL],
            })}
        />
    );
};

export default MyBriefsAssigned;
