import { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IBrief } from "./IBrief";

interface UpdateCoeditorsVariables {
    briefUuid: string;
    payload: string[];
}

export const useUpdateCoeditors = (config: MutateConfig<AxiosResponse<IBrief>, any, UpdateCoeditorsVariables>) => {
    const api = useApi();

    const updateCoeditors = async ({ payload, briefUuid }: UpdateCoeditorsVariables) =>
        api.patch<IBrief>(
            `briefs/${briefUuid}/coedition`,
            { coeditors: payload },
            {
                headers: { "Content-Type": "application/merge-patch+json" },
            },
        );

    return useMutation<AxiosResponse<IBrief>, any, UpdateCoeditorsVariables>(updateCoeditors, config);
};
